<form [formGroup]="form" class="questionnaire" *ngIf="questionnaire">
    <div class="questionnaire-container">
        <div class="intro" *ngIf="!started" [style.height]="getContentHeight() + 'px'">
            <img src="{{ questionnaire.image }}" alt="Image" *ngIf="questionnaire.image">
            <h1 class="mt-5 mb-3">{{ questionnaire.title }}</h1>
        </div>
        <div class="question" *ngIf="started && currentQuestion" [style.height]="getContentHeight() + 'px'">
            <div>
                <label class="control-label font-weight-bold" [class.text-center]="currentQuestion.type === 'five_stars'">{{ currentQuestion.title }}</label>
                <div class="actions" *ngIf="!currentQuestion.isRequired">
                    <a [routerLink]="" (click)="continue(true)">Saltar pregunta</a>
                </div>
            </div>
            <div class="answers">
                <div class="text-center" *ngIf="currentQuestion.type === 'ranking'">
                    <app-ranking [settings]="currentQuestion.settings" [formControl]="getFormControl()" type="numbers"></app-ranking>
                </div>
                <div class="text-center" *ngIf="currentQuestion.type === 'five_stars'">
                    <app-ranking [settings]="currentQuestion.settings" [formControl]="getFormControl()" type="stars"></app-ranking>
                </div>
                <div *ngIf="currentQuestion.hasNativeComponent() && currentQuestion.choices.length > 0 && currentQuestion.minAnswers === 1 && currentQuestion.maxAnswers === 1">
                    <mat-radio-group class="radio-group" [formControl]="getFormControl()">
                        <ng-container *ngFor="let choice of currentQuestion.choices; index as i;">
                            <mat-radio-button [value]="currentQuestion.choices[i]">
                                {{ currentQuestion.choices[i] }}
                            </mat-radio-button>
                        </ng-container>
                    </mat-radio-group>
                </div>
                <div *ngIf="currentQuestion.hasNativeComponent() && currentQuestion.choices.length > 0 && !(currentQuestion.minAnswers === 1 && currentQuestion.maxAnswers === 1)">
                    <ul>
                        <li *ngFor="let control of form.get('question_' + currentQuestion.id)['controls']; index as i;">
                            <mat-checkbox [formControl]="control" (ngModelChange)="updateChoices()" color="primary">
                                {{ currentQuestion.choices[i] }}
                            </mat-checkbox>
                        </li>
                    </ul>
                    <mat-hint *ngIf="currentQuestion.minAnswers === currentQuestion.maxAnswers && currentQuestion.minAnswers === 1">
                        {{ 'Select one option' | translate }}
                    </mat-hint>
                    <mat-hint *ngIf="currentQuestion.minAnswers === currentQuestion.maxAnswers && currentQuestion.minAnswers > 1">
                        {{ 'Select \{\{count\}\} options' | translate:{count: currentQuestion.minAnswers} }}
                    </mat-hint>
                    <mat-hint *ngIf="currentQuestion.minAnswers !== currentQuestion.maxAnswers">
                        {{ 'Select \{\{min\}\}-\{\{max\}\} options' | translate:{min: currentQuestion.minAnswers, max: currentQuestion.maxAnswers} }}
                    </mat-hint>
                </div>
                <div *ngIf="currentQuestion.hasNativeComponent() && currentQuestion.choices.length === 0 && (currentQuestion.minAnswers > 1 || currentQuestion.maxAnswers > 1)">
                    <div class="mb-2" *ngFor="let control of form.get('question_' + currentQuestion.id)['controls']; index as i;">
                        <app-field [control]="control" [question]="currentQuestion"></app-field>
                    </div>
                    <mat-hint *ngIf="currentQuestion.minAnswers === currentQuestion.maxAnswers && currentQuestion.minAnswers === 1">
                        {{ 'Enter one answer' | translate }}
                    </mat-hint>
                    <mat-hint *ngIf="currentQuestion.minAnswers === currentQuestion.maxAnswers && currentQuestion.minAnswers > 1">
                        {{ 'Enter \{\{count\}\} answers' | translate:{count: currentQuestion.minAnswers} }}
                    </mat-hint>
                    <mat-hint *ngIf="currentQuestion.minAnswers !== currentQuestion.maxAnswers">
                        {{ 'Enter \{\{min\}\}-\{\{max\}\} answers' | translate:{min: currentQuestion.minAnswers, max: currentQuestion.maxAnswers} }}
                    </mat-hint>
                </div>
                <div *ngIf="currentQuestion.hasNativeComponent() && currentQuestion.choices.length === 0 && (currentQuestion.minAnswers === 1 && currentQuestion.maxAnswers === 1)">
                    <app-field [control]="getFormControl()"
                        [question]="currentQuestion"></app-field>
                </div>
            </div>
        </div>
        <div class="actions">
            <p *ngIf="!started">
                <i class="far fa-clock mr-1"></i> {{ questionnaire.timeText }}
            </p>
            <p *ngIf="started">
                Pregunta {{ currentIndex + 1 }} de {{ questionnaire.questions.length }}
            </p>
            <button type="button" mat-raised-button color="primary" cdkFocusInitial style="width: 150px;" (click)="continue(false)">
                {{ started ? (currentIndex < questionnaire.questions.length - 1 ? 'Siguiente' : 'Terminar') : 'Iniciar' }}
            </button>
        </div>
    </div>
</form>
