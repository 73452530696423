import { Injectable } from '@angular/core';
import { ApiSaveResponse } from '../models/api-response';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class SubscribersService {

    constructor(
        private apiService: ApiService,
    ) { }

    public saveEmail(data: Object): Promise<ApiSaveResponse> {
        return this.apiService.request<ApiSaveResponse>('post', 'emails', data);
    }
}
