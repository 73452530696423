import {Component, ChangeDetectorRef, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';
import {Subscription} from 'rxjs';
import {MatSidenav} from '@angular/material/sidenav';
import {GoogleAnalyticsService} from '../../services/google-analytics.service';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnDestroy, AfterViewInit {
    @ViewChild('snav', {static: false}) sidenav: MatSidenav;
    public user: User;
    public subscription: Subscription;
    public env: string;
    public badgeHeight: number = 43;
    public headerHeight: number = 56;

    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        private authService: AuthService,
        private userService: UserService,
        public googleAnalyticsService: GoogleAnalyticsService,
    ) {
        this.env = environment.env;
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        this.user = this.authService.getCurrentUser();
        // Escuchar cambios al usuario autenticado
        this.subscription = this.userService.authUserUpdated$.subscribe(user => this.user = user);
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        if (this.env !== 'prod') {
            var ele = document.getElementsByClassName('dev-badge')[0] as any;
            this.badgeHeight = ele.offsetHeight;
            setTimeout(() => {
                this.headerHeight = 56 + this.badgeHeight;
            }, 500);
        }
    }

    public toggleSidenav() {
        this.sidenav.toggle();
        if (this.sidenav.opened) {
            this.googleAnalyticsService.eventEmitter("open_menu", "Menu");
        }
    }
}
