import {Component, Inject, OnInit} from '@angular/core';
import {NgForm, FormControl, FormGroupDirective, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router, ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {TermsConditionsComponent} from '../terms-conditions/terms-conditions.component';
import {GoogleAnalyticsService} from '../../services/google-analytics.service';
import {Utils} from '../../utils/index';
import {PrivacyComponent} from '../privacy/privacy.component';
import { BROWSER_STORAGE } from '../../storage';
import { BenefitsComponent } from '../benefits/benefits.component';
import { WindowService } from '../../services/window.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Source } from '../../models/source';
import { MatomoService } from '../../services/matomo.service';

/** Error when invalid control is dirty, touched, or submitted. */
export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        const invalidCtrl = !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
        let customError = false;
        if (control === form.form.get('email')) {
            customError = control.parent.hasError('emailNotConfirmed');
        }
        else if (control === form.form.get('password')) {
            customError = control.parent.hasError('passwordNotConfirmed');
        }
        const invalidParent = !!(control && control.parent && customError && control.parent.dirty);
        return (invalidCtrl || invalidParent);
    }
}

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    public formError: string = '';
    public formSuccess: string = '';
    public form: FormGroup;
    public matcher = new CustomErrorStateMatcher();
    public savingText: string = "Creating user account";
    public okText: string = "OK";
    public errors: {[k: string]: string[]} = {};
    public showForm = false;
    public ofLegalAge: boolean;

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        private fb: FormBuilder,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        public translate: TranslateService,
        private router: Router,
        private authService: AuthService,
        private userService: UserService,
        public googleAnalyticsService: GoogleAnalyticsService,
        private activatedRoute: ActivatedRoute,
        public windowService: WindowService,
        private matomoService: MatomoService,
    ) {
        this.translate.get([this.savingText, this.okText], {}).subscribe((trans) => {
            this.savingText = trans[this.savingText];
            this.okText = trans[this.okText];
        });
        this.clearErrors();
    }

    private clearErrors() {
        this.formError = '';
    }

    ngOnInit() {
        let sourcePage = this.activatedRoute.snapshot.queryParams['source'];
        let clickID = this.activatedRoute.snapshot.queryParams['ClickID'];
        let userCode = this.activatedRoute.snapshot.queryParams['user_code'];
        // Borrar sourcePage del localstorage
        this.storage.removeItem('sourcePage');
        this.storage.removeItem('clickID');
        this.storage.removeItem('userCode');
        // Guardar el sourcePage en el localstorage
        if (sourcePage && Source.validSources().indexOf(sourcePage) >= 0) {
            this.storage.setItem('sourcePage', sourcePage);
            if (sourcePage == 'ojo7') {
                this.storage.setItem('clickID', clickID);
            }
        } else {
            this.storage.setItem('sourcePage', 'webapp');
        }
        if (userCode) {
            this.storage.setItem('userCode', userCode);
            this.storage.setItem('sourcePage', sourcePage);
        }
        this.ofLegalAge = this.storage.getItem('isOver18') == '1';
        let controls = {
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            phone: [''],
            email: ['', Validators.email],
            email_confirmation: ['', Validators.email],
            password: ['', Validators.required],
            password_confirmation: ['', Validators.required],
            source: [this.storage.getItem('sourcePage')],
            onboarding: [this.storage.getItem('onboarding')],
            click_id: [this.storage.getItem('clickID')],
        };
        if (!this.ofLegalAge) {
            controls['ofLegalAge'] = [false, Validators.requiredTrue];
        }
        if (userCode) {
            controls['user_code'] = [userCode];
        }
        this.form = this.fb.group(controls, {
            validators: [this.emailConfirmedValidator, this.passwordConfirmedValidator]
        });
        document.getElementsByTagName('body')[0].classList.add('login-page');
    }

    ngOnDestroy() {
        document.getElementsByTagName('body')[0].classList.remove('login-page');
    }

    private emailConfirmedValidator(form: FormGroup) {
        const matches = form.get('email').value === form.get('email_confirmation').value;
        return !matches ? {emailNotConfirmed: true} : null;
    }

    private passwordConfirmedValidator(form: FormGroup) {
        const matches = form.get('password').value === form.get('password_confirmation').value;
        return !matches ? {passwordNotConfirmed: true} : null;
    }

    public onSubmit(): void {
        this.clearErrors();
        if (!this.form.valid) {
            return;
        }
        let ref = Utils.alert(this.dialog, {
            data: {
                message: this.savingText + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        let data = this.form.value;
        data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.authService.register(data).then((response) => {
            this.googleAnalyticsService.eventEmitter("sign_up", "Auth", "email");
            this.matomoService.trackEvent("Auth", "sign_up", "email");
            let redirectUrl = '';
            this.activatedRoute.queryParamMap.subscribe(queryParams => {
                redirectUrl = queryParams.has('redirect') ? queryParams.get("redirect") : '';
            });
            Utils.alert(this.dialog, {
                data: {
                    message: response.message,
                    type: 'success'
                }
            });
            let credentials = {
                email: this.form.get('email').value,
                password: this.form.get('password').value
            };
            this.authService.login(credentials).then((response) => {
                this.userService.getMe().catch(error => {});
                this.googleAnalyticsService.setCurrentUser(response.user.id);
                if (redirectUrl !== '') {
                    this.router.navigateByUrl(redirectUrl);
                } else {
                    this.router.navigate(['home'], {replaceUrl: true});
                }
            }).catch((response: HttpErrorResponse) => {
                if ([401, 422].indexOf(response.status) !== -1) {
                    this._snackBar.open(response.error.message, this.okText, {
                        duration: 8000
                    });
                    if (response.status === 422) {
                        if (response.error.errors) {
                            Object.keys(response.error.errors).forEach(key => {
                                this.errors[key] = [response.error.errors[key][0]];
                            });
                        }
                    }
                }
            }).finally(() => {
                setTimeout(() => ref.close(), 200);
            });
            this.showBenefits();
            // Limpiar variables en local storage
            this.storage.removeItem('sourcePage');
            this.storage.removeItem('clickID');
            this.storage.removeItem('userCode');
            this.storage.removeItem('onboarding');
        })
        .catch((response) => {
            if (response.status === 422) {
                this.formError = response.error.message;
                this._snackBar.open(response.error.message, this.okText, {
                    duration: 8000
                });
                if (response.error.errors) {
                    Object.keys(response.error.errors).forEach(key => {
                        const formControl = this.form.get(key);
                        if (formControl) {
                            formControl.setErrors({
                                serverError: response.error.errors[key][0]
                            });
                        }
                    });
                }
            }
            else if (response.message === 'Not token') {
                let snackBarRef = this._snackBar.open("No fue posible enviar la petición debido a un problema de autenticación, por favor recargue la página y vuelva a intentarlo", this.okText, {
                    duration: 15000
                });
                snackBarRef.afterDismissed().subscribe(null, null, () => {
                    window.location.reload();
                });
            }
        })
        .then(() => {
            ref.close();
        });
    }

    private showBenefits() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-component-benefits";
        dialogConfig.maxWidth = (this.windowService.innerWidth - 30) + "px";
        this.dialog.open(BenefitsComponent, dialogConfig);
    }

    public showTerms() {
        this.dialog.open(TermsConditionsComponent, {
            data: {}
        });
    }

    public showPrivacy() {
        this.dialog.open(PrivacyComponent, {
            data: {}
        });
    }
}
