import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CampaignService } from 'src/app/services/campaign.service';
import { Campaign } from 'src/app/models/campaign';
import { NgForm } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Balance } from '../../models/balance';
import { Utils } from '../../utils/index';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-donation-detail',
    templateUrl: './donation-detail.component.html',
    styleUrls: ['./donation-detail.component.scss']
})

export class DonationDetailComponent implements OnInit {
    public campaign: Campaign;
    public routerSubscription$: Subscription;
    public campaignId: number = 0;
    public state: { [k: string]: any } = {
        take: 20,
        page: 1,
        sort: 'created_at|desc',
        type: 'beneficial',
        name: '',
    }
    public formData: { [k: string]: any } = {
        donationAmount: '',
        campaignId: this.campaignId,
    };
    public errors: { [k: string]: string[] } = {};
    public balance: Balance;
    constructor(
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private campaignService: CampaignService,
        private userService: UserService,
    ) { }

    ngOnInit(): void {
        this.campaignId = this.activatedRoute.snapshot.params['id'];
        this.campaignService.getCampaign(this.campaignId, this.state)
            .then(response => this.campaign = response)
            .catch(error => { });

        this.userService.getMyBalance().then(balance => {
            this.balance = balance;
        }).catch(error => { }).then(() => {
        });
    }

    private clearErrors() {
        Object.keys(this.formData).forEach(key => {
            this.errors[key] = [];
        });
    }

    private clearForm() {
        Object.keys(this.formData).forEach(key => {
            this.formData[key] = '';
            this.errors[key] = [];
        });
    }

    public onSubmit(form: NgForm): void {
        this.clearErrors();
        if (!form.form.valid) {
            return;
        }
        if (!this.formData['donationAmount']) {
            return;
        }
        let data = Object.assign({}, this.formData);
        if (this.balance.available < 25) {
            Utils.alert(this.dialog, {
                data: {
                    message: "Necesitas acumular al menos MX $25 para poder donar.<br>¡Sigue subiendo tus tickets!",
                    type: 'info'
                }
            });
            return;
        }
        if (this.formData['donationAmount'] > this.balance.available) {
            Utils.alert(this.dialog, {
                data: {
                    message: "No cuentas con saldo suficiente para donar.<br>¡Sigue subiendo tus tickets!",
                    type: 'info'
                }
            });
            return;
        }
        data['campaignId'] = this.campaignId;
        Utils.confirm(this.dialog, {
            disableClose: true,
            data: {
                title: "Confirmar donación",
                message: "¿Desea confirmar la donación?",
                confirmButtonText: "Sí",
                cancelButtonText: "Cancelar",
                callback: () => {
                    this.campaignService.storeDontation(data).then((response) => {
                        Utils.alert(this.dialog, {
                            data: {
                                message: response.message,
                                type: 'success'
                            }
                        });
                        this.userService.getMyBalance().then(balance => {
                            this.balance = balance;
                        }).catch(error => { }).then(() => {
                            //
                        });
                        this.formData['donationAmount'] = null;
                    }).catch((error: HttpErrorResponse) => {
                        if (error.status === 401) {

                        }
                        else if (error.status === 422) {
                        }
                    }).then(() => {
                        //
                    });
                }
            }
        });
    }
}
