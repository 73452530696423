import { AbstractControl, FormControl } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { Question } from './../../models/question';

@Component({
    selector: 'app-field',
    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit {
    @Input("control") public control: FormControl;
    @Input("question") public question: Question;

    constructor() { }

    ngOnInit(): void {
    }

}
