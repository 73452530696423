<div class="login-box">
    <div class="auth-content">
        <div class="login-logo mt-5">
            <img src="assets/img/moneo-logo-fill-v1.svg" alt="Logo"/>
        </div>
        <mat-card class="login-content shadow-none px-0">
            <mat-card-content>
                <div class="alert alert-danger" *ngIf="formError">
                    {{ formError }}
                </div>
                <div class="alert alert-success" *ngIf="formSuccess">
                    <strong>{{ formSuccess }}</strong>
                </div>
                <p class="login-box-msg">
                    {{ 'Enter your new password' | translate }}
                </p>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <p>
                        <mat-form-field>
                            <mat-label>{{ "E-mail" | translate }}</mat-label>
                            <input type="text" matInput formControlName="email" required readonly>
                            <mat-error *ngIf="form.get('email').errors?.['serverError']">{{ form.get('email').errors?.['serverError'] }}</mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field>
                            <mat-label>{{ "Password" | translate }}</mat-label>
                            <input type="password" matInput formControlName="password" name="password" required [errorStateMatcher]="matcher" autocomplete="new-password">
                            <mat-error *ngIf="form.get('password').errors?.['required']">
                                {{ 'Please fill this field' | translate }}
                            </mat-error>
                            <mat-error *ngIf="form.hasError('passwordNotConfirmed')">
                                {{ "The password confirmation does not match" | translate }}
                            </mat-error>
                            <mat-error *ngIf="form.get('password').errors?.['serverError']">
                                {{ form.get('password').errors?.['serverError'] }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field>
                            <mat-label>{{ "Password confirmation" | translate }}</mat-label>
                            <input type="password" matInput formControlName="password_confirmation" name="password_confirmation" required autocomplete="new-password">
                            <mat-error *ngIf="form.get('password_confirmation').errors?.['required']">
                                {{ 'Please fill this field' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" mat-raised-button color="primary">{{ 'Update password' | translate }}</button>
                        </div>
                    </div>
                </form>
                <p class="mt-3 mb-1">
                    <a routerLink="/auth/login" [replaceUrl]="true">{{ 'Login' | translate }}</a>
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
