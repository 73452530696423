import { IUser } from './../models/user';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BROWSER_STORAGE } from '../storage';
import { User } from '../models/user';
import { Auth, IAuth } from '../models/auth';
import { environment } from '../../environments/environment';
import { ApiSaveResponse } from '../models/api-response';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private apiBaseUrl = 'http://localhost:3000/api';
    private clientTokenSource = new BehaviorSubject<Auth>(null);
    public clientToken$ = this.clientTokenSource.asObservable();

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        private http: HttpClient,
        private router: Router,
        public translate: TranslateService,
        private snackBar: MatSnackBar
    ) {
        if (environment.apiUrl) {
            this.apiBaseUrl = environment.apiUrl;
        }
    }

    public getAuthData(): Auth {
        let data = this.storage.getItem('app-token');
        return data ? new Auth(JSON.parse(data) as IAuth) : null;
    }

    public saveAuthData(data: IAuth): void {
        this.storage.setItem('app-token', JSON.stringify(data));
    }

    private handleError(error: HttpErrorResponse): Promise<any> {
        if (!window.navigator.onLine) {
            let msg = "No internet connection.";
            this.translate.get([msg, "OK"], {}).subscribe((trans) => {
                this.snackBar.open(trans[msg], trans["OK"], {
                    duration: 5000,
                });
            });
        }
        else if ([0, 504].indexOf(error.status) !== -1) {
            let msg = "Server timed out.";
            this.translate.get([msg, "OK"], {}).subscribe((trans) => {
                this.snackBar.open(trans[msg], trans["OK"], {
                    duration: 5000,
                });
            });
        }
        return Promise.reject(error);
    }

    public requestToken(): Promise<Auth> {
        let auth = this.getAuthData();
        if (auth && auth.expiresAt > Date.now()) {
            this.clientTokenSource.next(auth);
            return Promise.resolve(auth);
        }
        const url: string = `${this.apiBaseUrl}/oauth/token`;
        let data = {
            client_id: environment.apiClient.id,
            client_secret: environment.apiClient.secret,
            grant_type: 'client_credentials'
        }
        return firstValueFrom(
                this.http
                .post<IAuth>(url, data, {
                    headers: {
                        'Accept': 'application/json'
                    }
                })
            )
            .then(response => {
                let r = new Auth(response);
                this.saveAuthData(response);
                this.clientTokenSource.next(r);
                return r;
            })
            .catch((error: HttpErrorResponse) => this.handleError(error));
    }

    public login(credentials: Object): Promise<Auth> {
        const url: string = `${this.apiBaseUrl}/oauth/token`;
        let data: any = {
            username: credentials['email'],
            password: credentials['password'],
            client_id: environment.apiClient.id,
            client_secret: environment.apiClient.secret,
            grant_type: 'password'
        }
        if (credentials['provider']) {
            data.provider = credentials['provider'];
        }
        if (credentials['terms_accepted']) {
            data.terms_accepted = credentials['terms_accepted'];
        }
        return firstValueFrom(
                this.http
                .post(url, data, {
                    headers: {
                        'Accept': 'application/json'
                    }
                })
            )
            .then(response => {
                let r = new Auth(response as IAuth);
                this.saveAuthData(r.toJson());
                return r;
            })
            .catch((error: HttpErrorResponse) => this.handleError(error));
    }

    public socialLogin(provider: string, params: Object = {}): Promise<Auth> {
        const url: string = `${this.apiBaseUrl}/oauth/social/${provider}`;
        params['client_id'] = environment.apiClient.id;
        params['client_secret'] = environment.apiClient.secret;
        return firstValueFrom(
                this.http
                .post(url, params, {
                    headers: {
                        'Accept': 'application/json'
                    }
                })
            )
            .then(response => {
                let r = new Auth(response as IAuth);
                this.saveAuthData(r.toJson());
                return r;
            })
            .catch((error: HttpErrorResponse) => this.handleError(error));
    }

    public socialRequest<T>(url: string, provider: string, params: { [k: string]: any }): Promise<T> {
        if (!provider) {
            // No hay provider.
            // No seguir con el proceso.
            return Promise.reject({ message: "Not social provider" });
        }
        if (['google', 'facebook'].indexOf(provider) < 0) {
            // Driver no válido
            // No seguir con el proceso.
            return Promise.reject({ message: "Invalid provider" });
        }
        let auth = this.getAuthData();
        if (!auth) {
            // No hay token.
            // Intentar obtener uno.
            this.requestToken().catch(error => { });
            auth = this.getAuthData();
            if (!auth) {
                // No hay token.
                // No seguir con el proceso.
                return Promise.reject({ message: "Not token" });
            }
        }
        else if (auth.user != null) {
            // Hay usuario autenticado.
            // Redireccionar al home.
            this.router.navigate(['home']);
        }
        url = `${environment.apiUrl}/${url}`;
        return firstValueFrom(
                this.http
                .get<T>(url, {
                    headers: {
                        Authorization: auth.tokenType + ' ' + auth.accessToken
                    },
                    params
                })
            )
            .then((response) => {
                return response;
            })
            .catch((error: HttpErrorResponse) => this.handleError(error));
    }

    public requestPassword(credentials: Object): Promise<ApiSaveResponse> {
        let auth = this.getAuthData();
        if (!auth) {
            return Promise.reject({ message: "Not token" });
        }
        const url: string = `${this.apiBaseUrl}/password/email`;
        return firstValueFrom(
                this.http
                .post<ApiSaveResponse>(url, credentials, {
                    headers: {
                        Authorization: auth.tokenType + ' ' + auth.accessToken
                    }
                })
            )
            .then(response => response)
            .catch((error: HttpErrorResponse) => this.handleError(error));
    }

    public resetPassword(credentials: Object): Promise<ApiSaveResponse> {
        let auth = this.getAuthData();
        if (!auth) {
            return Promise.reject({ message: "Not token" });
        }
        const url: string = `${this.apiBaseUrl}/password/reset`;
        return firstValueFrom(
                this.http
                .post<ApiSaveResponse>(url, credentials, {
                    headers: {
                        Authorization: auth.tokenType + ' ' + auth.accessToken
                    }
                })
            )
            .then(response => response)
            .catch((error: HttpErrorResponse) => this.handleError(error));
    }

    public register(data: IUser): Promise<ApiSaveResponse> {
        let auth = this.getAuthData();
        if (!auth) {
            return Promise.reject({ message: "Not token" });
        }
        const url: string = `${this.apiBaseUrl}/users/register`;
        return firstValueFrom(
                this.http
                .post<ApiSaveResponse>(url, data, {
                    headers: {
                        Authorization: auth.tokenType + ' ' + auth.accessToken
                    }
                })
            )
            .then(response => response)
            .catch((error: HttpErrorResponse) => this.handleError(error));
    }

    public setLocale(lang: string): Promise<any> {
        const url: string = `${this.apiBaseUrl}/setlocale/${lang}`;
        return firstValueFrom(
                this.http
                .put(url, {}, { withCredentials: true })
            )
            .then(response => response)
            .catch((error: HttpErrorResponse) => this.handleError(error));
    }

    public logout(): Promise<any> {
        const url: string = `${this.apiBaseUrl}/oauth/token`;
        let headers = this.getHeaderData();
        headers = headers.set('Accept', 'application/json');
        return firstValueFrom(this.http.delete(url, { headers: headers }))
            .then(response => {
                return response;
            })
            .catch((error: HttpErrorResponse) => this.handleError(error))
            .finally(() => {
                this.storage.removeItem('app-token');
                this.storage.removeItem('isOver18');
                this.storage.removeItem('home_count');
            });
    }

    public isLoggedIn(): boolean {
        const data = this.getAuthData();
        if (data) {
            try {
                return data.expiresAt > Date.now() && data.refreshToken !== null;
            }
            catch (e) {
                return false;
            }

        } else {
            return false;
        }
    }

    public getCurrentUser(): User {
        const data = this.getAuthData();
        if (data && data.user) {
            return data.user;
        }
        return null;
    }

    public getHeaderData(): HttpHeaders {
        if (!this.isLoggedIn()) {
            this.storage.removeItem('app-token');
            this.requestToken().catch(error => { });
            this.router.navigate(['auth/login'], { replaceUrl: true });
            return null;
        }
        else {
            const data = this.getAuthData();
            let headers = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: data.tokenType + ' ' + data.accessToken
            });
            return headers;
        }
    }
}
