<div class="subscribers">
    <h1 class="h4 font-weight-bold text-center mb-4">¡Refiere a tus amigos y gana más!</h1>
    <p class="text-center mb-4">Por lo pronto nuestro programa de referidos es unicamente por invitación.</p>
    <button type="button" mat-raised-button color="primary" class="mb-5 d-block font-weight-bold w-100 text-uppercase" (click)="registerEmail()">
        Apúntame en la lista
    </button>
    <p class="mb-0 text-center">
        <span class="subscribers__close font-weight-bold" (click)="close()">No quiero participar</span>
    </p>
</div>
