<div>
    <p class="mb-2 subtitle">Acciones claves</p>
    <div class="key-actions p-3 pt-4">
        <div class="container p-0">
            <div class="row mb-3">
                <div class="col-6 col-md-4 col-lg-3 offset-md-2 offset-lg-3">
                    <button mat-raised-button color="yellow" class="key-actions__btn d-block font-weight-bold p-2 w-100 text-uppercase" (click)="redeem()">
                        Sube tus tickets
                    </button>
                </div>
                <div class="col-6 col-md-4 col-lg-3 d-flex align-items-center">
                    <p class="mb-0 font-weight-bold key-actions__text" (click)="showTutorial()">
                        Ver el tutorial
                        <i class="fas fa-question-circle"></i>
                    </p>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-6 col-md-4 col-lg-3 offset-md-2 offset-lg-3">
                    <button mat-raised-button color="yellow" class="key-actions__btn d-block font-weight-bold p-2 w-100 text-uppercase" (click)="showSubscribersDialog()">
                        Invita a tus amigos
                    </button>
                </div>
                <div class="col-6 col-md-4 col-lg-3 d-flex align-items-center">
                    <p class="mb-0 font-weight-bold">Únete a la lista de espera</p>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-md-4 col-lg-3 offset-md-2 offset-lg-3">
                    <button mat-raised-button color="yellow" class="key-actions__btn d-block font-weight-bold p-2 w-100 text-uppercase" (click)="profile()">
                        Completa tu perfil
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
