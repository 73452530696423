import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogOptions } from '../components/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent, AlertDialogOptions } from '../components/alert-dialog/alert-dialog.component';

export class Utils {
    public static confirm(dialog: MatDialog, config: MatDialogConfig<ConfirmDialogOptions>) {
        const dialogRef = dialog.open(ConfirmDialogComponent, {
            data: {
                title: config.data.title ?? null,
                message: config.data.message,
                confirmButtonText: config.data.confirmButtonText ?? "Yes, do it",
                cancelButtonText: config.data.cancelButtonText ?? "Cancel",
                onCancel: config.data.onCancel ?? void 0,
            },
            width: config.width ? config.width : '350px',
            disableClose: config.disableClose != null ? config.disableClose : false
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'OK' && config.data?.callback) {
                config.data.callback();
            }
        });
    };

    public static alert(dialog: MatDialog, config: MatDialogConfig<AlertDialogOptions>): MatDialogRef<AlertDialogComponent> {
        const dialogRef = dialog.open(AlertDialogComponent, {
            data: {
                title: config.data.title ?? null,
                message: config.data.message,
                acceptButtonText: config.data.acceptButtonText ?? "OK",
                showAcceptButton: config.data.showAcceptButton != null ? config.data.showAcceptButton : true,
                type: config.data.type ?? null
            },
            width: config.width ? config.width : '350px',
            disableClose: config.disableClose != null ? config.disableClose : false
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'OK' && config.data?.callback) {
                config.data.callback();
            }
        });
        return dialogRef;
    };

    public static secondsToHHmmss(seconds: number): string {
        let minutes: number = Math.floor(seconds / 60);
        seconds = seconds % 60;
        let hours: number = Math.floor(minutes / 60);
        minutes = minutes % 60;
        return (hours.toString().length < 2 ? hours.toString().leftPad(2, '0') : hours.toString())
            + ":" + minutes.toString().leftPad(2, '0') + ":" + seconds.toString().leftPad(2, '0');
    }

    public static numberFormat(num: number, decimals: number, decimalPoint?: string, thousandsSeparator?: string): string {
        let n = !isFinite(+num) ? 0 : +num;
        let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
        let sep = (typeof thousandsSeparator === 'undefined') ? ',' : thousandsSeparator;
        let dec = (typeof decimalPoint === 'undefined') ? '.' : decimalPoint;
        let toFixedFix = function (n: number, prec: number) {
            var k = Math.pow(10, prec);
            return Math.round(n * k) / k;
        },
            s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    }
}