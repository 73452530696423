<div class="container p-0">
    <div class="home-offers">
        <!--
        <div class="special-offer" *ngIf="!user.questionnaire && !installed && promptEvent && showTipBanner">
            <div class="close" (click)="closeBanner('tip')">
                <span><i class="far fa-times-circle"></i></span>
            </div>
            <h3 class="mb-3">Tip de la semana</h3>
            <p class="mb-3">
                Haz tu vida más fácil y agrega el acceso directo a Moneo a tu pantalla de inicio.
            </p>
            <div class="text-center">
                <button mat-raised-button color="primary" (click)="addToHomeScreen()">Haz clic aquí</button>
            </div>
        </div>
        -->
        <app-latest-winners></app-latest-winners>
        <div class="special-offer mt-3" *ngIf="user.questionnaire && showQuestionnaireBanner">
            <div class="close" (click)="closeBanner('questionnaire')">
                <span><i class="far fa-times-circle"></i></span>
            </div>
            <h3 class="mb-3">{{ user.questionnaire.bannerHeading }}</h3>
            <p class="mb-3 content">
                {{ user.questionnaire.bannerContent }}
            </p>
            <div class="text-center">
                <button mat-raised-button color="primary" (click)="showQuestionnaire()">
                    {{ user.questionnaire.bannerButtonText }}
                </button>
            </div>
        </div>
        <!--
        <div *ngIf="!user.questionnaire" class="special-offer">
            <p class="mb-2 subtitle" style="font-size: 1.2rem;">¡Tenemos una buena noticia!</p>
            <div class="mt-3 mb-3">
                <div class="text-center">
                    <b style="margin-left: 20px; font-weight: normal; font-size: 18px;">¡Ahora sólo necesitas acumular MXN $25 para solicitar una transferencia!
                    </b><br><br>
                </div>
            </div>
        </div>
        -->
        <div *ngIf="false && !user.questionnaire" class="special-offer">
            <p class="mb-2 subtitle" style="font-size: 1.2rem;"> ¡Gana cashback con tus tickets!</p>
            <div class="mt-3 mb-3">
                <div class="text-center">
                    <b style="margin-left: 20px; font-weight: normal; font-size: 18px;">Aprende cómo subir tu ticket y ganar.</b>
                    <br><br>
                    <button mat-raised-button color="primary" [routerLink]="['/tutorial']" class="btn-cashback">
                        Ver video
                    </button>
                </div>
            </div>
        </div>

        <div class="mt-3 mb-3">
            <app-favorite-commerces></app-favorite-commerces>
        </div>

        <div class="mb-3">
            <app-key-actions></app-key-actions>
        </div>

        <div *ngIf="false" style="height: auto;">
            <div class="mt-3 mb-3" matRipple>
                <div class="commerce-link more-commerces w-100" (click)="showBannerSoriana8()">
                    <img src="assets/img/minibanner-aguacate.png" alt="Banner Soriana 8%" class="w-100 commerce-logo">
                </div>
            </div>
        </div>
        <div class="offers mt-3 mb-3">
            <p class="mb-2 subtitle">Ofertas populares</p>
            <app-offers
                sortBy="random"
                [numItems]="16"
                [commerceId]="europeaUuid"
                [appendMore]="true"
                [reload]="false"
                [limitCommerceOffers]="4"
                (cardClick)="showDetails($event)"
                (shareClick)="shareWa($event)"></app-offers>
        </div>
    </div>
</div>
