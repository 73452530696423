<mat-card class="commerces-list">
    <mat-card-header>
        <div mat-card-avatar>
            <a backButton class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title>Regresar</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="campaign">
        <h2 class="mb-2 mt-3">{{ campaign.name }}</h2>
        <div class="row">
            <ng-container >
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-2">
                    <img [src]="campaign.photo ? campaign.photo : '/assets/img/no-image.png'"
                    class="card-img-top" alt="Photo">
                    <div class="card-body text-center">
                        <p>{{ campaign.details }}</p>
                        <div class="row mt-3">
                            <div class="col-12 align-items-center">
                                <form (ngSubmit)="onSubmit(productForm)" #productForm="ngForm">
                                    <div class="row">
                                        <div class="col-12">
                                            <hr class="mt-0"/>
                                            <div class="form-group">
                                                <label>Ingresa la cantidad a donar</label>
                                                <input type="text" class="form-control" name="donationAmount"
                                                    [(ngModel)]="formData['donationAmount']">
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <button type="button" class="btn btn-primary" (click)="onSubmit(productForm)">Donar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>
