import {Inject, Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {NgForm, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {GoogleAnalyticsService} from '../../services/google-analytics.service';
import {TranslateService} from '@ngx-translate/core';
import {TermsConditionsComponent} from '../terms-conditions/terms-conditions.component';
import {PrivacyComponent} from '../privacy/privacy.component';
import {MatDialog} from '@angular/material/dialog';
import {BROWSER_STORAGE} from '../../storage';
import {Utils} from '../../utils/index';
import {Auth} from '../../models/auth';
import { MatomoService } from '../../services/matomo.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginError: string = '';
    public submitted: boolean = false;
    form: FormGroup;
    public loginInvalid: boolean;
    public okText: string = "OK";

    public formFields: {[k: string]: any} = {
        email: '',
        password: ''
    };

    public errors: {[k: string]: string[]} = {};
    public redirectTo: string;
    public loggingText: string = "Logging in";
    public enterText: string = "At Moneo we are concerned with responsible consumption and we prevent the sale of alcohol to minors. Please answer responsibly.";
    public cancelButtonText: string = "Leave, I'm under 18";
    public confirmButtonText: string = "Enter, I'm over 18";

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        public dialog: MatDialog,
        private _snackBar: MatSnackBar,
        private fb: FormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private userService: UserService,
        public googleAnalyticsService: GoogleAnalyticsService,
        public translate: TranslateService,
        private matomoService: MatomoService,
    ) {
        Object.keys(this.formFields).forEach(key => {
            this.errors[key] = [];
        });
        this.translate.get([this.loggingText, this.okText], {}).subscribe((trans) => {
            this.loggingText = trans[this.loggingText];
            this.okText = trans[this.okText];
        });
    }

    public saveAuthData(data: Auth): void {
        this.storage.setItem('app-token', JSON.stringify(data));
    }

    ngOnInit() {
        this.form = this.fb.group({
            email: ['', Validators.email],
            password: ['', Validators.required]
        });
        document.getElementsByTagName('body')[0].classList.add('login-page');
        this.activatedRoute.queryParamMap.subscribe(queryParams => {
            let session = queryParams.get("session");
            if (session) {
                session = window.atob(session);
                let r = new Auth(JSON.parse(session));
                this.saveAuthData(r);
                window.location.href = '/home';
            }
            this.redirectTo = queryParams.get("redirect")
        });
        if (this.storage.getItem('isOver18') !== '1') {
            this.translate.get([this.enterText, this.cancelButtonText, this.confirmButtonText, "OK"], {}).subscribe((trans) => {
                this.enterText = trans[this.enterText];
                this.cancelButtonText = trans[this.cancelButtonText];
                this.confirmButtonText = trans[this.confirmButtonText];
                Utils.confirm(this.dialog, {
                    disableClose: true,
                    data: {
                        message: this.enterText,
                        confirmButtonText: this.confirmButtonText,
                        cancelButtonText: this.cancelButtonText,
                        onCancel: () => {
                            window.location.href = 'http://www.alcoholinformate.org.mx';
                        },
                        callback: () => {
                            this.storage.setItem('isOver18', '1');
                        }
                    }
                });
            });
        }
    }

    ngOnDestroy() {
        document.getElementsByTagName('body')[0].classList.remove('login-page');
    }

    public onLoginSubmit(form: NgForm): void {
        this.submitted = true;
        if (!form.form.valid) {
            return;
        }
        this.loginError = '';
        this.doLogin();
    }

    public doLogin() {
        this.loginError = '';
        if (!this.form.valid) {
            return;
        }
        let ref = Utils.alert(this.dialog, {
            data: {
                message: this.loggingText + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        this.formFields['email'] = this.form.get('email').value;
        this.formFields['password'] = this.form.get('password').value;
        this.authService.login(this.formFields).then((response) => {
            // Actualizar timezone del usuario
            let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            this.userService.updateTimezone(tz).then(response => {}).catch(error => {});
            this.userService.getMyBalance().catch(error => {});
            this.userService.getMe().catch(error => {});
            this.googleAnalyticsService.setCurrentUser(response.user.id);
            this.googleAnalyticsService.eventEmitter('login', 'Auth', 'email');
            this.matomoService.setUser(response.user.id);
            this.matomoService.trackEvent('Auth', 'login', 'email');
            if (this.redirectTo) {
                this.router.navigateByUrl(this.redirectTo, {replaceUrl: true});
            }
            else {
                this.router.navigate(['home'], {replaceUrl: true});
            }
        })
        .catch((response) => {
            if ([401, 422].indexOf(response.status) !== -1) {
                this._snackBar.open(response.error.message, this.okText, {
                    duration: 8000
                });
                if (response.status === 422) {
                    if (response.error.errors) {
                        Object.keys(response.error.errors).forEach(key => {
                            this.errors[key] = [response.error.errors[key][0]];
                        });
                    }
                }
            }
        })
        .finally(() => {
            setTimeout(() => ref.close(), 200);
        });
    }

    public showTerms() {
        this.dialog.open(TermsConditionsComponent, {
            data: {}
        });
    }

    public showPrivacy() {
        this.dialog.open(PrivacyComponent, {
            data: {}
        });
    }

    public forgotPassword() {
        this.googleAnalyticsService.eventEmitter('click_forgot_password', 'Auth');
        this.router.navigate(['/auth/password/reset']);
    }
}
