<div class="slider-tutorial" #elSliderTutorial (swipe)="onSwipe($event)">
    <section class="slider-tutorial__body">
        <ngb-carousel #carousel [showNavigationArrows]="false" [showNavigationIndicators]="false" [interval]="0"
            class="h-100" (slide)="onSlide($event)" (slid)="onSlid($event)">
            <ng-template ngbSlide id="slide-0">
                <p class="h2 text-center font-weight-bold mt-2 mb-4">¿Cómo funciona?</p>
                <p class="h5 text-center font-weight-bold mb-0">Busca ofertas y<br>compra en la tienda</p>
                <div class="picsum-img-wrapper p-2">
                    <img class="img-carousel d-block mw-100 mh-100 mx-auto" src="assets/img/slider-tutorial/a.png" alt="Imagen slider 1">
                </div>
            </ng-template>
            <ng-template ngbSlide id="slide-1">
                <p class="h2 text-center font-weight-bold mt-2 mb-4">¿Cómo funciona?</p>
                <p class="h5 text-center font-weight-bold mb-0">Sube una foto de tu ticket con<br>todos los datos visibles</p>
                <div class="picsum-img-wrapper p-2">
                    <img class="img-carousel d-block mw-100 mh-100 mx-auto" src="assets/img/slider-tutorial/b.png" alt="Imagen slider 2">
                </div>
            </ng-template>
            <ng-template ngbSlide id="slide-2">
                <p class="h2 text-center font-weight-bold mt-2 mb-4">¿Cómo ganas?</p>
                <p class="h5 text-center font-weight-bold mb-3">1. Ganas cashback sólo por<br>subir tu ticket</p>
                <p class="h5 text-center font-weight-bold mb-0">2. Ganas aún más si compras<br>las ofertas especiales</p>
                <div class="picsum-img-wrapper p-2">
                    <img class="img-carousel d-block mw-100 mh-100 mx-auto" src="assets/img/slider-tutorial/c.png" alt="Imagen slider 3">
                </div>
            </ng-template>
            <ng-template ngbSlide id="slide-3">
                <p class="h2 text-center font-weight-bold mt-2 mb-4">¿Cómo ganas?</p>
                <p class="h5 text-center font-weight-bold mb-0">Transfiere tus ganancias a tu<br>cuenta bancaria</p>
                <div class="picsum-img-wrapper p-2">
                    <img class="img-carousel d-block mw-100 mh-100 mx-auto" src="assets/img/slider-tutorial/d.png" alt="Imagen slider 4">
                </div>
                <button type="button" mat-raised-button color="primary" (click)="goToRegister()" class="d-block w-75 my-2 mx-auto">
                    ¡Regístrate ya!
                </button>
                <button type="button" mat-raised-button color="blue" (click)="goToOffers()" class="d-block w-75 mb-2 mx-auto text-white">
                    Ver ofertas
                </button>
            </ng-template>
        </ngb-carousel>
    </section>
    <footer class="slider-tutorial__footer px-2 py-3">
        <button type="button" class="btn btn-link text-white font-weight-bold text-uppercase p-1" *ngIf="activeSlide !== 'slide-3'" (click)="goToRegister()">
            Regístrate
        </button>
        <button type="button" class="btn btn-link text-white font-weight-bold text-uppercase p-1" *ngIf="activeSlide === 'slide-3'" (click)="closeModal()">
            Cerrar
        </button>
        <div class="slider-tutorial__indicators d-flex align-items-center justify-content-center">
            <div class="slider-tutorial__indicator" [class]="{'active': activeSlide === 'slide-0'}" (click)="select(0)"></div>
            <div class="slider-tutorial__indicator mx-1" [class]="{'active': activeSlide === 'slide-1'}" (click)="select(1)"></div>
            <div class="slider-tutorial__indicator mr-1" [class]="{'active': activeSlide === 'slide-2'}" (click)="select(2)"></div>
            <div class="slider-tutorial__indicator" [class]="{'active': activeSlide === 'slide-3'}" (click)="select(3)"></div>
        </div>
        <button type="button" class="btn btn-link text-white font-weight-bold text-uppercase p-1" *ngIf="activeSlide !== 'slide-3'" (click)="tutorialNext()">
            Siguiente
        </button>
    </footer>
</div>