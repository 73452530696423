import { Component, OnInit } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';
import { Campaign } from '../../models/campaign';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Balance } from '../../models/balance';
import { Utils } from '../../utils/index';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-donations',
    templateUrl: './donations.component.html',
    styleUrls: ['./donations.component.scss']
})

export class DonationsComponent implements OnInit {
    public campaign: Campaign = null;
    public routerSubscription$: Subscription;
    public campaignId: number = 0;
    public state: { [k: string]: any } = {
        take: 20,
        page: 1,
        sort: 'created_at|desc',
        type: 'beneficial',
        name: '',
        include: 'beneficial.photo',
    }
    public formData: { [k: string]: any } = {
        donationAmount: '',
        campaignId: this.campaignId,
    };
    public errors: { [k: string]: string[] } = {};
    public balance: Balance;
    constructor(
        public dialog: MatDialog,
        private campaignService: CampaignService,
        private userService: UserService,
    ) { }

    ngOnInit(): void {
        this.campaignService.getDefaultCampaign(this.state)
            .then(response => {
                this.campaign = response;
            })
            .catch(error => { });

        this.userService.getMyBalance().then(balance => {
            this.balance = balance;
        }).catch(error => { }).then(() => {
        });
    }

    private clearErrors() {
        Object.keys(this.formData).forEach(key => {
            this.errors[key] = [];
        });
    }

    public onSubmit(form: NgForm): void {
        this.clearErrors();
        if (!form.form.valid) {
            return;
        }
        if (!this.formData['donationAmount']) {
            return;
        }
        let data = Object.assign({}, this.formData);
        /*if (this.balance.available < 25) {
          Utils.alert(this.dialog, {
              message: "Necesitas acumular al menos MX $25 para poder donar.<br>¡Sigue subiendo tus tickets!",
              type: 'info'
          });
          return;
        }*/
        if (this.formData['donationAmount'] > this.balance.available) {
            Utils.alert(this.dialog, {
                data: {
                    message: "No cuentas con saldo suficiente para donar la cantidad ingresada.<br>¡Sigue subiendo tus tickets!",
                    type: 'info'
                }
            });
            return;
        }
        data['campaignId'] = this.campaign.id;
        Utils.confirm(this.dialog, {
            disableClose: true,
            data: {
                title: "Confirmar donación",
                message: "¿Desea confirmar la donación?",
                confirmButtonText: "Sí",
                cancelButtonText: "Cancelar",
                callback: () => {
                    this.campaignService.storeDontation(data).then((response) => {
                        this.userService.getMyBalance().then(balance => {
                            this.balance = balance;
                        }).catch(error => { }).then(() => {
                        });
                        this.formData['donationAmount'] = null;
                        Utils.alert(this.dialog, {
                            data: {
                                message: response.message,
                                type: 'info'
                            }
                        });
                    }).catch((error: HttpErrorResponse) => {
                        if (error.status === 401) {
                            //
                        }
                        else if (error.status === 422) {
                            Utils.alert(this.dialog, {
                                data: {
                                    message: error.error.message,
                                    type: 'info'
                                }
                            });
                        }
                    }).then(() => { });
                }
            }
        });
    }
}
