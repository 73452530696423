import { IQuestionnaire } from './../models/questionnaire';
import { ApiSaveResponse } from './../models/api-response';
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IUser, User } from '../models/user';
import { IRole, Role } from '../models/role';
import { Balance, IBalance } from '../models/balance';
import { History, IHistory } from '../models/history';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { BROWSER_STORAGE } from '../storage';
import { Questionnaire } from '../models/questionnaire';
import { IAuth } from '../models/auth';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private authUserUpdatedSource = new Subject<User>();
    public authUserUpdated$ = this.authUserUpdatedSource.asObservable();
    private myBalanceSource = new BehaviorSubject<Balance>(null);
    public myBalance$ = this.myBalanceSource.asObservable();
    public users: User[];
    public totalUsers: number;
    public showQuestionnaire: boolean = true;
    public showTip: boolean = true;

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        private apiService: ApiService,
        private authService: AuthService
    ) { }

    private updateAuthUser(userData: IUser) {
        const user = new User(userData);
        // Informar que se actualizó el usuario autenticado
        this.authUserUpdatedSource.next(user);
        // Actualizar storage
        let data = JSON.parse(this.storage.getItem('app-token')) as IAuth;
        data.user = userData;
        this.storage.setItem('app-token', JSON.stringify(data));
        return user;
    }

    public getMe(): Promise<User> {
        return this.apiService.request<IUser>('get', 'users/me', {})
            .then(response => this.updateAuthUser(response));
    }

    public getMyBalance(): Promise<Balance> {
        return this.apiService.request<IBalance>('get', 'users/me/balance', {})
            .then(response => {
                let balance = new Balance(response);
                this.myBalanceSource.next(balance);
                return balance;
            });
    }

    public getMyHistory(): Promise<History> {
        return this.apiService.request<IHistory>('get', 'users/me/history', {})
            .then(response => {
                let history = new History(response);
                return history;
            });
    }

    public resetBalance() {
        this.myBalanceSource.next(null);
    }

    public getRoles(): Promise<Role[]> {
        return this.apiService.request<IRole[]>('get', 'catalogues/roles', {})
            .then(response => response.map((data) => new Role(data)));
    }

    public updateMe(data: Partial<IUser>): Promise<ApiSaveResponse<IUser>> {
        return this.apiService.request<ApiSaveResponse<IUser>>('put', 'users/me', data).then(response => {
            this.updateAuthUser(response.data)
            return response;
        });
    }

    public updateTimezone(timezone: string): Promise<any> {
        return this.apiService.request('put', 'users/me/timezone', { timezone });
    }

    public getQuestionnaire(): Promise<Questionnaire> {
        return this.apiService.request<IQuestionnaire>('get', 'users/me/questionnaire', {})
            .then(response => Questionnaire.fromJson(response))
    }

    public sendAnswers(questionnaireId: number, data: Object[]): Promise<ApiSaveResponse> {
        return this.apiService.request<ApiSaveResponse>('put', 'users/me/answers', { questionnaire_id: questionnaireId, answers: data });
    }
}
