<h1 mat-dialog-title>{{ 'Notice of privacy' | translate }}</h1>
<div mat-dialog-content>
    <p><strong>Moneo Aviso de Privacidad para uso del Servicio https://www.moneo.cash/</strong></p>
    <p>Fecha de última actualización: 21 de junio de 2022</p>
    <p><strong>Yulah Ventures, SA. de CV. (“Moneo”) con domicilio en calle Georgia número 112-301, Colonia Nápoles, CP 03810, Delegación Benito Juárez, Ciudad de México,</strong> es el responsable del uso y protección de los datos personales que usted proporcione.</p>
    <p>Moneo está comprometido con la protección de su privacidad de conformidad con lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento, los Lineamientos del Aviso de Privacidad, así como con la normativa y disposiciones aplicables en materia de protección de datos personales (la “Ley”).</p>
    <p>El presente Aviso de Privacidad (en lo sucesivo el “Aviso”) tiene como finalidad dar a conocer el tratamiento que se hará respecto de los datos personales que proporcione cualquier persona que haga uso de los diferentes servicios ofrecidos por Moneo, (en lo sucesivo el “Usuario”).</p>
    <p><strong>Finalidades del tratamiento de datos personales</strong></p>
    <p>Sus datos personales serán utilizados para las siguientes finalidades que dan origen y se consideran necesarias para la existencia, mantenimiento y cumplimiento de la relación jurídica con Moneo:</p>
    <ol type="i">
        <li>Acceso a ofertas de empresas terceras a través de la cuenta Moneo (“Recompensas”), ofrecidas por Moneo y terceros con los que Moneo opere, así como los servicios que Moneo ofrezca (en lo sucesivo, los “Servicios"), a través de sus sitios web, aplicaciones móviles y cualquier otro programa o software de Moneo derivado y/o vinculado (en lo sucesivo el “Sitio”), donde Moneo proporcione sus Servicios;</li>
        <li>Proporcionar, personalizar y mejorar su experiencia con los Servicios puestos a disposición del Usuario;</li>
        <li>Garantizar el funcionamiento técnico de los Servicios puestos a disposición del Usuario y analizar el uso de los mismos, incluida su interacción con aplicaciones;</li>
        <li>Comunicarnos con el Usuario a efectos relacionados con el uso de los Servicios;</li>
        <li>Registro interno de los Servicios;</li>
        <li>Asistencia técnica que Moneo proporcione al Usuario;</li>
        <li>Mantener una comunicación activa con el Usuario y mantenerlo informado y actualizado respecto a la funcionalidad y/o cambios a los Servicios;</li>
        <li>Notificar sobre ofertas especiales y ofertas personalizadas de Moneo y mercadotecnia de Moneo;</li>
        <li>Obtener la información solicitada en los formularios de contacto, forma de pre-registro, forma de registro, encuestas, forma para solicitar la transferencia de las Recompensas a la cuenta bancaria del Usuario.</li>
        <li>Obtener las imágenes cargadas al Sitio por el Usuario relacionadas con recibos de compra para que el Usuario pueda obtener acceso a Recompensas.</li>
        <li>Obtener los correos electrónicos enviados al Sitio por el Usuario relacionados con recibos de compra para que el Usuario pueda obtener acceso a Recompensas.</li>
        <li>Verificar y confirmar su identidad;</li>
        <li>Dar seguimiento cuando al registrarse solicite formar parte de una lista de direcciones de correos electrónicos;</li>
        <li>Cumplir con procedimientos administrativos internos;</li>
        <li>Crear bases de datos para fines administrativos internos;</li>
        <li>Almacenar y tratar los datos personales en los medios que Moneo estime convenientes, incluyendo en servicios, aplicaciones e infraestructura en el cómputo en la nube;</li>
        <li>Atender solicitudes, preguntas o comentarios realizados por usted; y</li>
        <li>Enviarle notificaciones sobre cambios al presente Aviso de Privacidad;</li>
    </ol>
    <p>Adicionalmente, si usted no se opone, Moneo podrá tratar su información personal para llevar a cabo las siguientes finalidades que no son necesarias para proveer los Servicios, pero que nos permiten y facilitan brindarle una mejor atención:</p>
    <ol type="i">
        <li>Diagnosticar problemas y administrar los Servicios;</li>
        <li>Mejoras a nuestra mercadotecnia y promocionales;</li>
        <li>Análisis del uso de nuestro Sitio; </li>
        <li>Mejorar las características y contenido de nuestro Sitio;</li>
        <li>Comunicaciones sobre nuevos servicios;</li>
        <li>Enviarle información relativa a Moneo, sus filiales, subsidiarias y accionistas en México y a nivel mundial;</li>
        <li>Envío de información, revistas y noticias de temas que nosotros consideremos que pueden interesarle, así como noticias, comunicaciones o publicidad de Moneo o sus empresas relacionadas en México o a nivel mundial;</li>
        <li>Proporcionar su información a terceros interesados en realizar negocios, otorgar financiamientos, adquirir las acciones o activos de Moneo o sus accionistas o filiales en México o alrededor del mundo o en fusionarse con Moneo;</li>
        <li>Crear bases de datos para fines de investigación, estadísticos o de mercado;</li>
        <li>Invitarlo a eventos organizados por Moneo o por empresas filiales o subsidiarias.</li>
        <li>Crear bases de datos para fines de mercadotecnia y compartir información sobre datos de consumo de los Usuarios con las empresas con las cuales Moneo tiene relaciones comerciales para el ofrecimiento de servicios personalizados y ofertas por parte de diversos establecimientos comerciales al Usuario.</li>
    </ol>
    <p><i>Mecanismo para manifestar la negativa</i><p>
    <p>Es importante mencionarle que Moneo solo utilizará sus datos para las finalidades secundarias previamente descritas, cuando usted las haya consentido previamente. Usted tendrá 5 días para rechazar las finalidades secundarias mencionadas en este párrafo del Aviso de Privacidad, para lo cual podrá enviar un correo electrónico a: soporte@moneo.mx</p>
    <p>La negativa para el uso de sus datos personales para estas finalidades no podrá ser un motivo para que le neguemos los Servicios.</p>

    <p><strong>Datos Personales que se tratarán</strong></p>
    <p>El Usuario reconoce y acepta que al acceder a los Servicios de Moneo éste podrá obtener determinados datos personales solicitados para efectos de identificación (los “Datos Personales”), como pueden ser:</p>
    <ol type="i">
        <li>Nombre completo (Nombre(s) y apellidos);</li>
        <li>Dirección de correo electrónico;</li>
        <li>Contraseña;</li>
        <li>Número telefónico (en caso que sea agregada por el Usuario de manera voluntaria);</li>
        <li>Los datos del recibo de compra que serán utilizados incluyen nombre del comercio, número de ticket, fecha y hora de compra, sucursal del comercio de la compra, montos de productos comprados, nombres de productos comprados, precios de productos, descuentos aplicados, forma de pago.</li>
        <li>Número de CLABE, nombre de Banco, número de cuenta, nombre completo del titular de la cuenta bancaria. Es importante destacar que solo se recaban los datos financieros antes mencionados de los Usuarios con el fin de que el Usuario pueda redimir a su cuenta bancaria las Recompensas, por lo que no será necesario que el Usuario otorgue su consentimiento, toda vez que Moneo obtiene únicamente los datos financieros con el propósito de cumplir las obligaciones derivadas de la relación jurídica entre el Usuario y Moneo, para la prestación de los Servicios.</li>
        <li>Fotografía del rostro del Usuario, imagen o avatar (en caso que sea agregada por el Usuario de manera voluntaria).</li>
        <li>Ocupación actual;</li>
        <li>Domicilio;.</li>
        <li>Género;</li>
        <li>Edad; y</li>
        <li>Datos relacionados con preferencias de consumo obtenidos mediante las respuestas a las encuestas realizadas a los Usuarios de manera voluntaria sobre datos demográficos, intereses generales, razones de compra, datos patrimoniales,entre otros.</li>
    </ol>
    <p>Moneo le podrá permitir al Usuario realizar su conexión y/o acceso a los Servicios a través de Facebook. El Usuario reconoce y acepta que al momento de iniciar sesión a través de Facebook, Moneo podrá recopilar información sobre él.</p>
    <p><i>Datos no identificables</i></p>
    <p>Del mismo modo Moneo podrá tener acceso a información no identificable del Usuario, como su dirección IP, su interacción con nuestros Servicios, su ubicación, datos de geolocalización, zona horaria, URL de referencia, navegador, sistema operativo, la información del teléfono móvil, información de cookies, el proveedor de datos transferidos y servicios de Internet.</p>
    <p><i>Datos personales sensibles</i></p>
    <p>Moneo para la prestación de Servicios no recopila datos personales sensibles de los Usuarios de conformidad con la definición del artículo 3, fracción VI de la Ley. </p>
    <p>En relación con las operaciones realizadas con farmacias o compra de medicamentos, le pedimos al Usuario de manera atenta eliminar o marcar en negro los nombres o cualquier dato relacionado con medicamentos o productos que pudieran revelar de alguna manera el estado de salud  presente o futuro del Usuario. </p>
    <p>En caso de que usted no otorgue su consentimiento para el tratamiento de sus Datos Personales podemos vernos imposibilitados para brindarle los Servicios del Sitio. Para cualquier duda o comentario en relación con los Datos Personales en posesión de Moneo, usted podrá contactar a nuestro Departamento de Privacidad a través del correo electrónico: soporte@moneo.mx</p>
    <p><strong> Transferencias de Datos Personales que en su caso se efectúen</strong></p>
    <p>Le informamos que sus datos personales y patrimoniales pueden ser compartidos dentro y fuera del país con las empresas con las cuales Moneo tiene relaciones comerciales para el ofrecimiento de servicios personalizados y ofertas por parte de diversos establecimientos comerciales al Usuario, para lo cual Moneo requiere su consentimiento para transferir los datos personales y patrimoniales para las finalidades descritas anteriormente(*).</p>
    <p>Cabe destacar que Moneo de ninguna manera transferirá sus datos bancarios o financieros relacionados con su número de CLABE, nombre de Banco, número de cuenta, nombre completo del titular de la cuenta bancaria.</p>
    <p>Moneo podrá transferir sus Datos Personales con todas o alguna(s) de nuestras empresas filiales, subsidiarias, y/o pertenecientes al mismo grupo económico en México o en el extranjero siempre que éstas operen bajo los mismos procesos y políticas internas; en estos supuestos, Moneo podrá transferir sus datos sin mediar autorización expresa y para las finalidades descritas anteriormente.</p>
    <p>Asimismo, Moneo se reserva el derecho de compartir sus Datos Personales con autoridades administrativas, judiciales o gubernamentales de cualquier tipo, en México o en el extranjero, siempre que así se establezca por mandato judicial o administrativo o que una ley así lo determine.</p>
    <p>Moneo compartirá su información cuando subcontrata a terceros, sean abogados, auditores, contadores o encargados de sistemas que requieran procesar y almacenar su información por cuenta de Moneo y bajo las instrucciones de Moneo. Moneo no requiere su consentimiento para estas transferencias.</p>
    <p>De igual forma, sus Datos Personales, podrán ser compartidos con cualquier prospecto, comprador potencial o adquirente de la empresa que mantiene Moneo. Asimismo, se podrán compartir sus Datos Personales a terceros interesados en realizar negocios o establecer una relación jurídica con nosotros, otorgar financiamientos, adquirir las acciones o activos de Moneo o sus accionistas o filiales en México o alrededor del mundo, o en fusionarse con Moneo.</p>
    <p>Le informamos que para las transferencias indicadas anteriormente con un asterisco (*) requerimos obtener su consentimiento expreso:</p>
    <p><strong>Otorgo mi consentimiento expreso para las siguientes transferencias de mis datos personales:</strong></p>
    <p><strong>Transferencias con las empresas con las cuales Moneo tiene relaciones comerciales para el ofrecimiento de servicios personalizados y ofertas comerciales al Usuario.</strong></p>
    <p>Salvo esos casos, Moneo no compartirá o transferirá sus Datos Personales a terceros salvo en los casos previstos en la Ley, su Reglamento o cualquier otra normatividad aplicable.</p>
    <p><strong>¿Cómo se almacenan sus datos?</strong></p>
    <p>Moneo podrá almacenar cualquier información que el Usuario ingrese en el Sitio por virtud de los Servicios adquiridos, o bien a través de la comunicación vía correo electrónico u otro tipo de comunicación que entre el Usuario y Moneo y/o su área de servicio al cliente pueda existir, con la finalidad de mantener un registro interno de los servicios y/o asistencia técnica que Moneo proporcione al Usuario, así como para mantener una comunicación activa con el Usuario y mantenerlo informado y actualizado respecto a la funcionalidad y/o cambios a los Servicios, así como a las ofertas especiales que podrían convenir al Usuario.</p>

    <p><strong>Google Analytics y otras herramientas de análisis</strong></p>
    <p>Moneo podrá utilizar una herramienta llamada "Google Analytics" o cualquier otra que se le asemeje para recoger información sobre el uso sus Servicios, tales como el uso de las diferentes ofertas disponibles.</p>
    <p>Google Analytics planta una cookie permanente en su navegador para identificar al Usuario como único la próxima vez que visite el Sitio. Esta cookie no puede ser utilizado por cualquier persona, más que Google, Inc.</p>
    <p>Además, usamos una funcionalidad llamada UserID para monitorear cómo usted utiliza los Servicios.</p>
    <p>Moneo utilizará la información recibida de Google Analytics o cualquier otra herramienta que se le asemeje para los propósitos explicados en este documento.</p>
    <p>Si desea inhabilitar el uso de datos con Google Analytics, descargue e instale el complemento para su navegador web: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a></p>

    <p><strong>Complementación de la información</strong></p>
    <p>Moneo podrá complementar la información de identificación personal que el Usuario proporcione, con información de fuentes de terceros (ej.: información de nuestros socios estratégicos, proveedores de servicios, etc), lo anterior con la finalidad de mejorar la capacidad de los Servicios de Moneo y ofrecer al Usuario oportunidades para adquirir productos y/o servicios que a consideración de Moneo pueden ser de interés para el Usuario.</p>

    <p><strong>Referencias</strong></p>
    <p>Si el Usuario decide utilizar nuestro servicio de referencia para informar a un amigo y/o contacto conocido acerca de los Servicios de Moneo éste último podrá solicitarle al Usuario: nombre del amigo y/o contacto conocido, número celular y su dirección de correo electrónico. El Usuario reconoce y acepta que Moneo  podrá enviar automáticamente al amigo y/o contacto conocido un correo electrónico invitándole a visitar el Sitio y/o aplicación de Moneo.</p>
    <p>Moneo almacenará esta información con el único fin de enviar únicamente un correo a la referencia que el Usuario proporcione a Moneo. El amigo referido por el Usuario podrá contactar a Moneo a través del dato de contacto que éste le haya proporcionado para solicitar la eliminación de esta información de la base de datos de Moneo.</p>

    <p><strong>Uso de cookies</strong></p>
    <p>Le informamos que en nuestra página de Internet utilizamos cookies a través de las cuales es posible monitorear su comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar en el Sitio.</p>
    <p>Usamos cookies para guardar los datos de login y también para analizar el uso del Servicio con Google Analytics.</p>
    <p>Una cookie es un archivo de información almacenado en el equipo de cómputo del Usuario y vinculado con información sobre dicho Usuario. Moneo podrá utilizar tanto cookies de sesión como cookies permanentes. Las cookies de sesión, una vez que el Usuario cierra su navegador o se desconecta, terminan y se borran. Una cookie permanente es un pequeño archivo de texto almacenado en el disco duro del equipo de cómputo del Usuario por un largo periodo de tiempo.</p>
    <p>Las cookies de sesión pueden ser usadas por Moneo para conocer las preferencias del Usuario mientras este visita la página web. También ayudan a minimizar los tiempos de descarga y a ahorrar procesamientos del servidor. Las cookies permanentes pueden utilizarse por Infomovil por ejemplo, para registrar si el Usuario quiere o no que su contraseña se recuerde o información similar.</p>
    <p>Las cookies que se utilizan en la página web de Moneo no contienen información personal que identifique al Usuario.</p>
    <p>El Usuario podrá configurar su navegador para que no se le instalen cookies permanentes o para recibir un aviso en pantalla cada vez que una cookie quiera instalarse, pudiendo decidir en cada momento si la quiere aceptar o no. Igualmente el Usuario podrá decidir posteriormente eliminar las cookies que tenga instaladas cambiando la configuración de su navegador. El Usuario deberá tener en cuenta que la eliminación de las cookies puede suponer una disminución en la calidad o en las funcionalidades de la página web.</p>
    <p>Se informa a los Usuarios de la página web de Moneo que se entenderá que otorgan su consentimiento para la instalación y uso por parte de Moneo de cookies si navegan por dicha página web y no configuran su navegador para evitarlo. Adicionalmente, se entenderá que los Usuarios otorgan su consentimiento expreso al uso de cookies si no configuran su navegador para no recibirlas y aceptan la presente Política de Privacidad.</p>
    <p>Para mayor información sobre el uso de estas tecnologías, podrá dirigirse por correo electrónico a soporte@moneo.mx</p>

    <p><strong>Datos voluntarios y obligatorios</strong></p>
    <p>Salvo en los campos en que expresamente se determine lo contrario, las respuestas a las preguntas sobre datos personales son voluntarias, sin que la falta de contestación a dichas preguntas implique una merma en la calidad de los servicios que el Usuario solicita. La falta de cumplimentación de los campos determinados como obligatorios o el suministro de datos incorrectos imposibilitará que Moneo pueda darle de alta como Usuario o prestarle los servicios solicitados.</p>

    <p><strong>Mantenimiento de los datos</strong></p>
    <p>Una vez concluida la relación contractual con el Usuario, Moneo se reserva el derecho de conservar los datos del Usuario que se dé de baja durante el tiempo necesario para cumplir con sus obligaciones legales. Durante ese tiempo los datos personales permanecerán bloqueados y no serán utilizados para ninguna otra finalidad que no sea la antedicha. Una vez concluidos los plazos para su mantenimiento los datos serán eliminados.</p>
    <p>Moneo mantiene los niveles de protección de sus datos personales conforme a la normativa vigente en materia de medidas de seguridad, y ha implementado todos los medios técnicos a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos que el Usuario facilite a Infomovil. No obstante, Infomovil no puede garantizar la seguridad absoluta de los datos. Cualquier otra información como detalles de pago recogidos para transacciones comerciales, se encriptará antes de ser transmitida del Usuario a Moneo.</p>

    <p><strong>Derechos del Usuario</strong></p>
    <p>El Usuario tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada conforme a los principios, deberes y obligaciones previstas en la normativa (Cancelación); así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>
    <p>Para ejercer cualquiera de sus derechos, podrá dirigirse por escrito enviando un e-mail a soporte@moneo.mx con los datos que se indican a continuación:</p>
    <p>La solicitud de acceso, rectificación, cancelación u oposición deberá contener y acompañar lo siguiente: (1) El nombre del Titular y domicilio u otro medio para comunicarle la respuesta a su solicitud; (2) Los documentos que acrediten la identidad o, en su caso, la representación legal del Titular; (3) La descripción clara y precisa de los Datos respecto de los que se busca ejercer alguno de los derechos antes mencionados, y (4) Cualquier otro elemento o documento que facilite la localización de los Datos del Titular.</p>
    <p>Para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, usted podrá ponerse en contacto con nuestro Departamento de Privacidad, que dará trámite inmediato a las solicitudes para el ejercicio de estos derechos, y atenderá cualquier duda que pudiera tener respecto al tratamiento de su información. Los datos de contacto son los siguientes:</p>
    <p>Departamento de Privacidad <br />soporte@moneo.mx </p>

    <p><strong>¿Cómo puede revocar su consentimiento para el uso de sus datos personales?</strong></p>
    <p>Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando el Servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>
    <p>Para revocar su consentimiento deberá presentar su solicitud al Departamento de Privacidad mediante correo electrónico a soporte@moneo.mx, quienes le informarán sobre el procedimiento y requisitos para la revocación del consentimiento.</p>

    <p><strong>Opciones para limitar el uso o divulgación de sus Datos Personales</strong></p>
    <p>Con el objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos los siguientes medios:</p>
    <ol type="i">
        <li>Su inscripción en el Registro Público para Evitar Publicidad, que está a cargo de la Procuraduría Federal del Consumidor, con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para mayor información sobre este registro, usted puede consultar el portal de Internet de la PROFECO, o bien ponerse en contacto directo con ésta.</li>
        <li>Su registro en el listado de exclusión publicitario, a fin de que sus datos personales no sean tratados para fines mercadotécnicos, publicitarios o de prospección comercial por nuestra parte. Para mayor información enviar un correo electrónico a la siguiente dirección soporte@moneo.mx.</li>
    </ol>

    <p><strong>Cambios en el Aviso de Privacidad</strong></p>
    <p>El presente Aviso de Privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales, de nuestras propias necesidades por los productos que ofrecemos, de nuestras prácticas de privacidad, de cambios en nuestro modelo de negocio, o por otras causas.</p>
    <p>Nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente Aviso de Privacidad, a través de publicaciones generales en nuestro sitio web: https://www.moneo.cash/</p>

    <p><strong>Autoridad de Protección de Datos</strong></p>
    <p>En caso de considerarlo necesario, le informamos que tiene el derecho de acudir ante el Instituto Nacional de Transparencia Acceso a la Información y Protección de Datos Personales (“INAI”) para hacer valer cualquier inconformidad relacionada con el tratamiento de sus datos personales por parte de Moneo.</p>

    <p><strong>Preguntas</strong></p>
    <p>Cualquier pregunta relativa al presente Aviso de Privacidad podrá dirigirse al siguiente correo electrónico: soporte@moneo.mx</p>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()" cdkFocusInitial>{{ 'OK' | translate }}</button>
</div>
