export const environment = {
    env: 'staging',
    locale: 'es',
    webAppUrl: 'https://app.stage01.moneo.clh.no',
    apiUrl: 'https://admin.stage01.moneo.clh.no/api/v1',
    apiClient: {
        id: 4,
        secret: 'IXiFtKlweOBdfINdKe2tAruUWWvzgukJXrxSTMSO'
    },
    sentry: {
        dsn: 'https://0d4cb21e6d0247f39657729aee9b1883@sentry02.cl.clh.no/25',
        tracingOrigins: ['localhost', 'https://app.stage01.moneo.clh.no'],
        tracesSampleRate: 0.1,
    },
    analytics: {
        trackingCode: 'UA-162598352-1',
        trackingCodeGA4: 'G-BLCF8JS9WQ',
    },
    google: {
        clientId: '870963938547-8au8hr42qj0tbnb92pdmab3q18j4fv1u.apps.googleusercontent.com'
    },
    facebook: {
        appId: '4043166235696672',
        version: 'v13.0',
    }
};
