<div class="ranking-container">
    <div class="row m-0 mb-2" *ngIf="type === 'numbers'">
        <div *ngFor="let option of makeOptions()" [ngClass]="'cols-' + settings.num_options"
                class="option" [class.text-center]="settings.num_options > 3">
            <span [class.selected]="option == selectedOption" mat-ripple (click)="onSelectOption(option)">
                {{ option }}
            </span>
        </div>
    </div>
    <div class="row mb-2 justify-content-center five-stars" *ngIf="type === 'stars'">
        <span *ngFor="let option of makeOptions()">
            <i [ngClass]="option <= selectedOption ? 'fas' : 'far'" class="star fa-star" (click)="onSelectOption(option)"></i>
        </span>
    </div>
    <div class="row" [class.justify-content-center]="type === 'stars'">
        <div class="col-6" [ngClass]="type === 'numbers' ? 'text-left' : 'text-center'">
            <i class="icon" [ngClass]="settings.start_icon"></i>
            <p class="label">{{ settings.start_label }}</p>
        </div>
        <div class="col-6" [ngClass]="type === 'numbers' ? 'text-right' : 'text-center'">
            <i class="icon" [ngClass]="settings.end_icon"></i>
            <p class="label">{{ settings.end_label }}</p>
        </div>
    </div>
</div>