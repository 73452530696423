<div class="login-box">
    <div class="auth-content">
        <div class="login-logo mt-5">
            <img src="assets/img/moneo-logo-fill-v1.svg" alt="Logo" />
        </div>
        <app-social-login></app-social-login>
        <p class="accept-terms">
            Al crear una cuenta, aceptas los
            <a href="#" (click)="$event.preventDefault(); showTerms();">Términos y condiciones</a>
            y el <a href="#" (click)="$event.preventDefault(); showPrivacy();">Aviso de Privacidad</a>
            de Moneo.
        </p>
        <p class="text-center font-weight-bold mb-4" style="opacity: .7;">
            {{ 'or' | translate }}
        </p>
        <div class="alert alert-danger" *ngIf="loginError">
            {{ loginError }}
        </div>
        <form [formGroup]="form" (ngSubmit)="doLogin()">
            <p>
                <mat-form-field>
                    <mat-label>{{ "E-mail" | translate }}</mat-label>
                    <input type="text" matInput formControlName="email" name="email" required
                        autocomplete="new-password" (input)="form.patchValue({email: form.get('email').value.trim()})">
                    <mat-error>
                        {{ 'Please enter a valid e-mail address' | translate }}
                    </mat-error>
                    <mat-error *ngFor="let msg of errors['email']">{{ msg }}</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field>
                    <mat-label>{{ "Password" | translate }}</mat-label>
                    <input type="password" matInput formControlName="password" name="password" required
                        autocomplete="new-password">
                    <mat-error>
                        {{ 'Please fill this field' | translate }}
                    </mat-error>
                </mat-form-field>
            </p>
            <div class="button text-center mb-3">
                <button mat-raised-button color="primary">{{ 'Login' | translate }}</button>
            </div>
        </form>
        <p class="mb-1 text-center">
            <span class="mr-2">{{ "Don't you have your Moneo account yet?" | translate }}</span><br>
            <a routerLink="/auth/register">{{ 'Join now' | translate }}</a>
        </p>

        <p class="mt-3 mb-1 text-center">
            <a href="#" (click)="$event.preventDefault(); forgotPassword();">{{ 'Forgot my password' | translate }}</a>
        </p>
    </div>
</div>