import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { IQuestionSettings } from 'src/app/models/question';

@Component({
    selector: 'app-ranking',
    templateUrl: './ranking.component.html',
    styleUrls: ['./ranking.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: RankingComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: RankingComponent
        }
    ]
})
export class RankingComponent implements ControlValueAccessor, Validator {
    @Input("settings") public settings: IQuestionSettings;
    @Input("type") public type: string;
    public onChange = (option) => { };
    public onTouched = () => { };
    public touched: boolean = false;
    public disabled: boolean = false;
    public selectedOption: number = 0;

    public onSelectOption(option: number) {
        this.markAsTouched();
        if (!this.disabled) {
            this.selectedOption = option;
            this.onChange(this.selectedOption);
        }
    }

    public writeValue(option: number) {
        this.selectedOption = option;
    }

    public registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    public registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    public markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    public setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const option = control.value;
        if (option <= 0) {
            return {
                min: {
                    option
                }
            };
        }
        return null;
    }

    public makeOptions(): number[] {
        let options: number[] = [];
        for (let i = 1; i <= this.settings.num_options; i++) {
            options.push(i);
        }
        return options;
    }
}
