import { HttpErrorResponse } from '@angular/common/http';
import { Question } from './../../models/question';
import { UserService } from './../../services/user.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WindowService } from 'src/app/services/window.service';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { AbstractControl, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Moment } from 'moment';
import { QuestionService } from '../../services/question.service';
import { Questionnaire } from '../../models/questionnaire';
import { Utils } from '../../utils';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-questionnaire',
    templateUrl: './questionnaire.component.html',
    styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {
    public closeIcon = faTimesCircle;
    public currentQuestion: Question;
    public currentIndex: number = 0;
    public form: FormGroup;
    public currentRate: number = 4;
    public questionnaire: Questionnaire;
    public started: boolean = false;
    public finished: boolean = false;
    public submitted: boolean = false;
    public answers = [];

    constructor(
        private fb: FormBuilder,
        public dialog: MatDialog,
        private router: Router,
        public windowService: WindowService,
        public userService: UserService,
        public questionService: QuestionService,
    ) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            test: [null]
        });
        this.fetchQuestionnaire();
    }

    public getContentHeight() {
        let noContentH = 64 + 77 + 30 + 20;
        if (environment.env !== 'prod') {
            noContentH += document.querySelector<HTMLElement>(".dev-badge").offsetHeight;
        }
        return this.windowService.innerHeight - noContentH;
    }

    private fetchQuestionnaire() {
        let ref = Utils.alert(this.dialog, {
            data: {
                message: 'Cargando cuestionario...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        this.userService.getQuestionnaire().then(result => {
            this.questionnaire = result;
            this.initPage();
        })
        .catch(() => { })
        .then(() => ref.close());
    }

    private initPage() {
        if (this.questionnaire.questions.length > 0) {
            this.form.removeControl('test');
            this.currentQuestion = this.questionnaire.questions[0];
            this.initForm(this.questionnaire.questions[0]);
        }
    }

    private initForm(question: Question) {
        // Si es opción múltiple
        if (question.choices.length > 0) {
            if (question.minAnswers === 1 && question.maxAnswers === 1) {
                this.form.addControl(`question_${question.id}`, this.fb.control(null, Validators.required));
            }
            else {
                this.form.addControl(`question_${question.id}`, this.fb.array(question.choices.map(c => false)));
            }
        }
        // Si se debe ingresar más de una respuesta
        else if (question.minAnswers > 1 || question.maxAnswers > 1) {
            let data = [];
            for (let i = 0; i < question.maxAnswers; i++) {
                if (i < question.minAnswers) {
                    data.push(this.getFormControlSettings(question));
                }
                else {
                    data.push(null);
                }
            }
            this.form.addControl(`question_${question.id}`, this.fb.array(data));
        }
        else {
            let settings = this.getFormControlSettings(question);
            this.form.addControl(`question_${question.id}`, this.fb.control(settings[0], settings[1]));
        }
        // Log question
        this.questionService.logViewed(question.id).catch(() => { });
    }

    public getFormControlSettings(question: Question) {
        let settings = [null, [Validators.required]];
        if (['integer', 'numeric', 'age'].indexOf(question.type) !== -1 && question.settings) {
            settings[1].push(Validators.min(question.settings['min_value']));
            settings[1].push(Validators.max(question.settings['max_value']));
        }
        else if (question.type === 'email') {
            settings[1].push(Validators.email);
        }
        else if (question.type === 'phone') {
            settings[1].push(Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'));
        }
        else if (question.type === 'url') {
            settings[1].push(Validators.pattern('^https?:\\/\\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$'));
        }
        return settings;
    }

    public continue(skip : boolean) {
        if (!this.started) {
            this.started = true;
            return;
        }
        if (!this.finished) {
            this.addAnswer(skip);
        }
        if (this.finished && !this.submitted) {
            this.submit();
        }
    }

    private addAnswer(skip : boolean) {
        if (!skip) {
            if (!this.form.valid) {
                return;
            }
            // Si es opción múltiple y debe seleccionar más de una opción, validar mínimo de respuestas seleccionadas
            if (this.currentQuestion.choices.length > 0 && !(this.currentQuestion.minAnswers === 1 && this.currentQuestion.maxAnswers === 1)) {
                let value = this.form.get(`question_${this.currentQuestion.id}`).value as boolean[];
                let checked = value.filter(v => v === true);
                if (checked.length < this.currentQuestion.minAnswers) {
                    return;
                }
            }
            // Armar respuesta
            let data = this.form.value;
            // Si es opción múltiple y debe seleccionar más de una opción
            if (this.currentQuestion.choices.length > 0 && !(this.currentQuestion.minAnswers === 1 && this.currentQuestion.maxAnswers === 1)) {
                // Añadir respuestas seleccionadas
                let selected = [];
                data[`question_${this.currentQuestion.id}`].forEach((checked: boolean, i: number) => {
                    if (checked) {
                        selected.push(this.currentQuestion.choices[i]);
                    }
                });
                data[`question_${this.currentQuestion.id}`] = selected;
            }
            // Si el campo es una fecha
            else if (['date', 'datetime'].indexOf(this.currentQuestion.type) !== -1) {
                let format = this.currentQuestion.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss';
                // Si debe ingresar más de una respuesta
                if (this.currentQuestion.minAnswers > 1 || this.currentQuestion.maxAnswers > 1) {
                    let dates = [];
                    // Dar formato a fechas seleccionadas
                    data[`question_${this.currentQuestion.id}`].forEach((d: Moment) => {
                        if (d) {
                            dates.push(d.format(format));
                        }
                    });
                    data[`question_${this.currentQuestion.id}`] = dates;
                }
                else {
                    // Formatear fecha seleccionada
                    data[`question_${this.currentQuestion.id}`] = (data[`question_${this.currentQuestion.id}`] as Moment).format(format);
                }
            }
            this.answers.push({
                question_id: this.currentQuestion.id,
                answer: data[`question_${this.currentQuestion.id}`]
            });
        }

        if (this.currentIndex < this.questionnaire.questions.length - 1) {
            this.form.removeControl(`question_${this.currentQuestion.id}`);
            this.currentIndex++;
            this.initForm(this.questionnaire.questions[this.currentIndex]);
            this.currentQuestion = this.questionnaire.questions[this.currentIndex];
        }
        else {
            this.finished = true;
        }
    }

    public submit() {
        this.submitted = true;
        // Enviar respuesta
        this.userService.sendAnswers(this.questionnaire.id, this.answers).then((response) => {
            this.userService.getMe().catch(error => { });
            Utils.alert(this.dialog, {
                data: {
                    message: response.message,
                    type: 'success'
                }
            });
            this.router.navigate(['home']);
        }).catch((response: HttpErrorResponse) => {
            if (response.status === 422) {
                this.submitted = false;
                if (response.error.errors) {
                    Object.keys(response.error.errors).forEach(key => {
                        const formControl = this.form.get(key);
                        if (formControl) {
                            formControl.setErrors({
                                serverError: response.error.errors[key][0]
                            });
                        }
                    });
                }
            }
        });
    }

    public updateChoices() {
        let value = this.form.get(`question_${this.currentQuestion.id}`).value as boolean[];
        let checked = value.filter(v => v === true);
        let controls = this.form.get(`question_${this.currentQuestion.id}`)['controls'] as AbstractControl[];
        controls.forEach((control, i) => {
            if (!control.value) {
                if (checked.length === this.currentQuestion.maxAnswers) {
                    controls[i].disable();
                }
                else {
                    controls[i].enable();
                }
            }
        })

    }

    public getFormControl(): FormControl {
        return this.form.get(`question_${this.currentQuestion.id}`) as FormControl;
    }
}
