import { Injectable, Inject } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../environments/environment';
import { BROWSER_STORAGE } from '../storage';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private apiBaseUrl = 'http://localhost:3000/api';

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        private http: HttpClient,
        private authService: AuthService,
        private router: Router,
        public translate: TranslateService,
        private snackBar: MatSnackBar
    ) {
        if (environment.apiUrl) {
            this.apiBaseUrl = environment.apiUrl;
        }
    }


    private handleError(error: HttpErrorResponse): Promise<any> {
        if (!window.navigator.onLine) {
            let msg = "No internet connection.";
            this.translate.get([msg, "OK"], {}).subscribe((trans) => {
                this.snackBar.open(trans[msg], trans["OK"], {
                    duration: 5000,
                });
            });
        }
        else if ([0, 504].indexOf(error.status) !== -1) {
            let msg = "Server timed out.";
            this.translate.get([msg, "OK"], {}).subscribe((trans) => {
                this.snackBar.open(trans[msg], trans["OK"], {
                    duration: 5000,
                });
            });
        }
        else if (error.status === 401 && this && this.router) {
            this.storage.removeItem('app-token');
            this.router.navigate(['auth/login']);
        }
        else if (error.status === 403) {
            this.translate.get(["OK"], {}).subscribe((trans) => {
                this.snackBar.open(error.error.message, trans["OK"], {
                    duration: 5000,
                });
            });
            this.router.navigate(['home']);
        }
        else if (error.status === 500) {
            let msg = "An error occurred on the server when your request was being processed, we will solve the problem as soon as possible.";
            this.translate.get([msg, "OK"], {}).subscribe((trans) => {
                this.snackBar.open(trans[msg], trans["OK"], {
                    duration: 10000,
                });
            });
        }
        return Promise.reject(error);
    }

    public downloadExportFile(urlPath: string, type: string, data: { [k: string]: any }): Promise<BlobPart> {
        let url: string = `${this.apiBaseUrl}/${urlPath}?to-excel=1`;
        const headers = this.authService.getHeaderData();
        const httpOptions: { [k: string]: any } = {
            headers: headers,
            responseType: 'blob'
        };
        if (data) {
            url += '&';
            Object.keys(data).forEach(key => {
                if (data[key] != null) {
                    url += key + '=' + data[key] + '&';
                }
            });
        }
        return firstValueFrom(this.http.get(url, httpOptions))
            .then(response => response)
            .catch(this.handleError);
    }

    public request<T = any>(method: string, urlPath: string, data: Object): Promise<T> {
        let url: string = `${this.apiBaseUrl}/${urlPath}`;
        let headers = this.authService.getHeaderData();
        let httpOptions = {
            headers: headers
        };
        let req: Observable<T> = null;
        if (method === 'get') {
            if (data) {
                url += '?';
                Object.keys(data).forEach(key => {
                    if ([null, undefined, ''].indexOf(data[key]) === -1) {
                        url += key + '=' + data[key] + '&';
                    }
                });
            }
            req = this.http.get<T>(url, httpOptions);
        }
        else if (method === 'post' || method === 'put') {
            if (data instanceof FormData) {
                if (httpOptions.headers !== null) {
                    httpOptions.headers = httpOptions.headers.delete('Content-Type');
                }
                req = this.http[method]<T>(url, data, httpOptions);
            }
            else {
                req = this.http[method]<T>(url, data, httpOptions);
            }
        }
        else if (method === 'delete') {
            httpOptions['body'] = data;
            req = this.http.delete<T>(url, httpOptions);
        }
        return firstValueFrom(req)
            .then(response => response)
            .catch((error: HttpErrorResponse) => this.handleError(error));
    }

    public requestWithClientToken<T = any>(urlPath: string): Promise<T> {
        let auth = this.authService.getAuthData();
        if (!auth) {
            return Promise.reject({ message: "Not token" });
        }
        const url: string = `${this.apiBaseUrl}/${urlPath}`;
        return firstValueFrom(
            this.http
                .get<T>(url, {
                    headers: {
                        Authorization: auth.tokenType + ' ' + auth.accessToken
                    }
                })
        )
            .then(response => response)
            .catch((error: HttpErrorResponse) => this.handleError(error));
    }

    public downloadFile(filename: string, type: string, data: BlobPart) {
        let mime = 'application/vnd.ms-excel';
        let ext = 'xlsx';
        if (type === 'csv') {
            mime = 'text/csv';
            ext = 'csv';
        }
        else if (type === 'pdf') {
            mime = 'application/pdf';
            ext = 'pdf';
        }
        const blob = new Blob([data], { type: mime });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename + '.' + ext;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
