export interface IQuestionSettings {
    num_options?: number;
    start_label?: string;
    end_label?: string;
    start_icon?: string;
    end_icon?: string;
    max_value?: number;
    min_value?: number;
}

export interface IQuestion {
    id: number;
    title: string;
    min_answers: number;
    max_answers: number;
    choices: string[];
    slot: number;
    is_required: boolean;
    choices_random: boolean;
    type: string;
    settings?: IQuestionSettings;
    multiple?: boolean;
}

export class Question {
    constructor(
        public id: number,
        public title: string,
        public minAnswers: number,
        public maxAnswers: number,
        public choices: string[],
        public slot: number,
        public isRequired: boolean,
        public choicesInRandomOrder: boolean,
        public type: string,
        public settings?: IQuestionSettings,
    ) { }

    public static fromJson(data: IQuestion): Question {
        let q = new Question(
            data['id'],
            data['title'],
            data['min_answers'],
            data['max_answers'],
            data['choices'] ?? [],
            data['slot'],
            data['is_required'],
            data['choices_random'],
            data['type'],
            data['settings'],
        );
        if (q.choicesInRandomOrder) {
            q.choices = q.choices.sort(() => {
                let r = Math.random();
                return r > .5 ? r * 1 : r * -1;
            });
        }
        return q;
    }

    public hasNativeComponent() {
        return ['five_stars', 'ranking'].indexOf(this.type) === -1;
    }
}