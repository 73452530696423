import { Component, OnInit, Inject, NgZone, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, IsActiveMatchOptions } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { Utils } from '../../utils/index';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BROWSER_STORAGE } from '../../storage';
import { BenefitsComponent } from '../../pages/benefits/benefits.component';
import { WindowService } from '../../services/window.service';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { Source } from '../../models/source';
import { MatomoService } from '../../services/matomo.service';

@Component({
    selector: 'app-social-login',
    templateUrl: './social-login.component.html',
    styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnInit, OnDestroy {
    public isSocial = { facebook: false, google: false };
    public okText: string = "OK";
    public loggingText: string = "Logging in";
    public textButton = 'Sign in with';
    public email: string;
    public redirectTo: string;
    public provider: string;
    public googleBtnWith: number;
    public isRegisterRoute: boolean = false;

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        public dialog: MatDialog,
        private authService: AuthService,
        private userService: UserService,
        private _snackBar: MatSnackBar,
        public translate: TranslateService,
        private router: Router,
        private zone: NgZone,
        private activatedRoute: ActivatedRoute,
        public googleAnalyticsService: GoogleAnalyticsService,
        public windowService: WindowService,
        private matomoService: MatomoService,
    ) { }

    ngOnInit(): void {
        this.translateTexts();
        this.loadFacebookSdk();
        this.loadGoogleSignInClient();
        const matchOptions: IsActiveMatchOptions = {
            paths: 'exact',
            matrixParams: 'ignored',
            queryParams: 'ignored',
            fragment: 'ignored'
        };
        this.storage.removeItem('redirect_after_login_social');
        this.activatedRoute.queryParamMap.subscribe(queryParams => {
            this.redirectTo = queryParams.get("redirect");
            if (this.redirectTo) {
                this.storage.setItem("redirect_after_login_social", this.redirectTo);
            }
        });
        const authWidth = document.querySelector<HTMLElement>('.auth-content').offsetWidth;
        this.googleBtnWith = authWidth - 30;
        if (this.router.isActive('auth/register', matchOptions)) {
            this.textButton = 'Sign up with';
            this.isRegisterRoute = true;
        }
    }

    ngOnDestroy(): void {
        // Eliminar script de fb si existe
        const fbScript = document.getElementById("fb-sdk");
        if (fbScript) {
            fbScript.parentNode.removeChild(fbScript);
        }
        // Eliminar script gsi client si existe
        let gsiScript = document.getElementById("gsi-client");
        if (gsiScript) {
            gsiScript.parentNode.removeChild(gsiScript);
        }
        gsiScript = document.getElementById("googleidentityservice");
        if (gsiScript) {
            gsiScript.parentNode.removeChild(gsiScript);
        }
    }

    private async translateTexts() {
        this.loggingText = await firstValueFrom(this.translate.get(this.loggingText, {}));
    }

    private loadGoogleSignInClient() {
        // Eliminar script gsi client si existe
        let elScript = document.getElementById("gsi-client");
        if (elScript) {
            elScript.parentNode.removeChild(elScript);
        }
        elScript = document.getElementById("googleidentityservice");
        if (elScript) {
            elScript.parentNode.removeChild(elScript);
        }
        // Cagar script gsi client
        const script = document.createElement('script');
        script.setAttribute('id', 'gsi-client');
        script.onload = () => {
            this.initGoogleSignIn();
        }
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        document.querySelector('body').appendChild(script)
    }

    private initGoogleSignIn() {
        google.accounts.id.initialize({
            client_id: environment.google.clientId,
            callback: (response) => {
                this.zone.run(() => {
                    this.provider = 'google';
                    this.doSocialLogin(response.credential);
                });
            }
        });
        google.accounts.id.renderButton(
            document.getElementById("googleButton"),
            {
                theme: "outline",
                size: "large",
                width: this.googleBtnWith,
                logo_alignment: 'left',
                text: this.isRegisterRoute ? "signup_with" : "signin_with",
            }
        );
        google.accounts.id.prompt();
    }

    private loadFacebookSdk() {
        // Eliminar script de fb si existe
        const fbScript = document.getElementById("fb-sdk");
        if (fbScript) {
            fbScript.parentNode.removeChild(fbScript);
        }
        window['fbAsyncInit'] = () => {
            FB.init({
                appId: environment.facebook.appId,
                cookie: true,
                xfbml: false,
                version: environment.facebook.version
            });
        };
        // Cagar script de fb
        const script = document.createElement('script');
        script.setAttribute('id', 'fb-sdk');
        script.src = 'https://connect.facebook.net/en_US/sdk.js';
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        document.querySelector('body').appendChild(script)
    }

    public signInWithFacebook() {
        this.provider = 'facebook';
        FB.login((response) => {
            if (response.authResponse) {
                this.zone.run(() => this.doSocialLogin(response.authResponse.accessToken));
            }
        }, { scope: 'email' });
    }

    public doSocialLogin(token: string = null) {
        let ref = this.openLoadingAlert();
        let params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
        if (['facebook', 'google'].indexOf(this.provider) !== -1) {
            params = this.provider === 'facebook'
                ? { access_token: token }
                : { credential: token };
        }
        let sourcePage = this.storage.getItem('sourcePage');
        let clickID = this.storage.getItem('clickID');
        let userCode = this.storage.getItem('userCode');
        if (sourcePage && Source.validSources().indexOf(sourcePage) >= 0) {
            params['source'] = sourcePage;
        } else {
            params['source'] = 'webapp';
        }
        if (clickID) {
            params['click_id'] = clickID;
        }
        if (userCode) {
            params['user_code'] = userCode;
        }
        const validOnboardingKeys = [
            'laeuropea1'
        ];
        let onboarding = this.storage.getItem('onboarding');
        // Validar que onboarding sea válido
        if (onboarding && validOnboardingKeys.indexOf(onboarding) >= 0) {
            params['onboarding'] = onboarding;
        }
        this.authService.socialLogin(this.provider, params).then((response) => {
            this.userService.getMe().catch(error => { });
            this.googleAnalyticsService.setCurrentUser(response.user.id);
            this.matomoService.setUser(response.user.id);
            if (response.created) {
                this.showBenefits();
                this.googleAnalyticsService.eventEmitter('sign_up', 'Auth', `${this.provider}`);
                this.matomoService.trackEvent('Auth', 'sign_up', `${this.provider}`);
            }
            else {
                this.googleAnalyticsService.eventEmitter('login', 'Auth', `${this.provider}`);
                this.matomoService.trackEvent('Auth', 'login', `${this.provider}`);
            }
            if (this.redirectTo) {
                this.router.navigateByUrl(this.redirectTo, { replaceUrl: true });
            }
            else {
                this.router.navigate(['home'], { replaceUrl: true });
            }
            // Limpiar variables en local storage
            this.storage.removeItem('sourcePage');
            this.storage.removeItem('clickID');
            this.storage.removeItem('userCode');
            this.storage.removeItem('onboarding');
        }).catch(response => this.handleError(response))
        .finally(() => setTimeout(() => ref.close(), 200));
    }

    private handleError(response: any) {
        if (response.status && [400, 401].indexOf(response.status) !== -1) {
            this._snackBar.open(response.error.message, this.okText, {
                duration: 8000
            });
        } else if (response.message) {
            console.log(response.message);
        } else {
            console.log(response);
        }
    }

    private showBenefits() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.id = "modal-component-benefits";
        dialogConfig.maxWidth = (this.windowService.innerWidth - 30) + "px";
        this.dialog.open(BenefitsComponent, dialogConfig);
    }

    public openLoadingAlert() {
        let ref = Utils.alert(this.dialog, {
            data: {
                message: this.loggingText + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        return ref;
    }

}
