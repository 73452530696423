<mat-card class="commerces-list">
    <mat-card-header>
        <div mat-card-avatar>
            <a backButton class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title>Regresar</mat-card-title>
    </mat-card-header>

    <mat-card-content *ngIf="!campaign">
        <h3>En este momento no tenemos campañas.</h3>
    </mat-card-content>
    <mat-card-content *ngIf="campaign">
        <h3 class="mb-2 mt-3" style="font-size: 24px;">{{ campaign.name }}</h3>
        <div class="row">
            <div class="container text-center">
                <div class="text-center mb-3">
                    <img [src]="campaign.photo ? campaign.photo : '/assets/img/no-image.png'"
                    class="card-img-top" alt="Photo">
                </div>
                <form (ngSubmit)="onSubmit(productForm)" #productForm="ngForm">
                    <div class="row">
                        <div class="col-12">
                            <hr class="mt-0"/>
                            <div *ngIf="balance">
                                El saldo en tu cuenta es de : $ {{ balance.available }}
                            </div>
                            <mat-form-field style="width: 100%;">
                                    <mat-label style="font-size: 24px;">Ingresa la cantidad a donar</mat-label>
                                    <input type="number" step="0.01" matInput 
                                    name="donationAmount" [(ngModel)]="formData['donationAmount']"
                                    style="font-size: 24px;" required>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
                <button type="button" class="btn btn-primary" (click)="onSubmit(productForm)"
                style="
                width: 200px;
                font-size: 24px;
                background: #00ADEF;
                border: 1px solid #000000;
                box-shadow: 3px 3px #000c;
                border-radius: 4px;
                font-weight: 700;">Donar</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>

