<div class="tutorial">
  <span class="close" (click)="closeModal()">
    <i class="fas fa-times"></i>
  </span>
  <div class="title">
    <br>
    <h2>¿Cómo ganar con Moneo?</h2>
  </div>
  <video muted autoplay loop playsinline [style.max-height]="vHeight + 'px'">
    <source src="assets/img/tutorial/MONEO25.mp4?ngsw-bypass=true" type="video/mp4" />
  </video>
</div>