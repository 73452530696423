import { Injectable } from '@angular/core';
import { Bank, IBank } from '../models/bank';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class CatalogueService {
    public banks: Bank[];

    constructor(
        private apiService: ApiService
    ) { }

    public getBanks(params: Object): Promise<Bank[]> {
        if (this.banks) {
            return Promise.resolve(this.banks);
        }
        return this.apiService.request<IBank[]>('get', 'catalogues/banks', params).then(response => {
            this.banks = response.map((data) => new Bank(data));
            return this.banks;
        });
    }
}
