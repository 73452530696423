
export class Source {
    source: string;

    constructor(source: string) {
        this.source = source;
    }

    public static validSources(): string[] {
        return  [
            'supermercadoscuentatotal1dic',
            'supermercadoscuentatotal2dic',
            'dineroreal1dic',
            'home',
            'walmart1dic',
            'superama1dic',
            'walmart1enesegmentoa',
            'walmart1enesegmentob',
            'ojo7',
            'superbowl',
            'referal-julio',
            'referal-jorge',
            'referal-montserrat',
            'referal-cesarcortes',
            'referal-cesaribarra',
            'referal-jose',
            'referal-eloisa',
            'guest_browsing',
            'amy-campaign-1',
            'franca-campaign-1',
            'neptuno-campaign-1',
            'facebook',
            'whatsapp',
            'email',
        ];
    }
}
