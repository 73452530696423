import * as moment from 'moment';
import { IBeneficalCause } from './benefical-cause';

export interface ICampaign {
    id: number;
    name: string;
    type: string;
    details: string;
    is_active: number;
    created_at: string;
    object_id: number;
    number: string;
    from: string;
    to: string;
    beneficial: IBeneficalCause;
}

export class Campaign {
    constructor(
        public id: number,
        public name: string,
        public type: string,
        public details: string,
        public isActive: boolean,
        public createdAt: string,
        public objectId: number,
        public number: string,
        public from: Date,
        public to: Date,
        public photo: string
    ) {}

    public static fromJson(data: ICampaign): Campaign {
        return new Campaign(
            data['id'],
            data['name'],
            data['type'],
            data['details'],
            data['is_active'] == 1,
            data['created_at'],
            data['object_id'],
            data['number'],
            data['from'] ? new Date(`${data['from']}T00:00:00`) : null,
            data['to'] ? new Date(`${data['to']}T00:00:00`) : null,
            data['beneficial'].photo ? data['beneficial'].photo.path: null,
        );
    }

    public getCreationDate(): string {
        return moment(this.createdAt).format('DD/MM/YYYY');
    }
}