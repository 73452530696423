<div class="register-box">
    <div class="auth-content">
        <div class="register-logo mt-5">
            <h3 class="m-0">
                {{ 'Register' | translate }}
            </h3>
        </div>
        <app-social-login></app-social-login>
        <p>
            <button type="button" class="btn btn-block btn-register-email" (click)="showForm = true">{{ 'Sign up with' | translate }} email</button>
        </p>
        <mat-card class="register-content px-0" *ngIf="showForm">
            <mat-card-content>
                <div class="alert alert-danger" *ngIf="formError">
                    {{ formError }}
                </div>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <p>
                        <input type="hidden" formControlName="source" name="source" value="webapp">
                    </p>
                    <p>
                        <mat-form-field>
                            <mat-label>{{ "First name" | translate }}</mat-label>
                            <input type="text" matInput formControlName="first_name" name="first_name" required>
                            <mat-error *ngIf="form.get('first_name').errors?.['required']">
                                {{ 'Please fill this field' | translate }}
                            </mat-error>
                            <mat-error *ngIf="form.get('first_name').errors?.['serverError']">
                                {{ form.get('first_name').errors?.['serverError'] }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field>
                            <mat-label>{{ "Last name" | translate }}</mat-label>
                            <input type="text" matInput formControlName="last_name" required name="last_name">
                            <mat-error *ngIf="form.get('last_name').errors?.['required']">
                                {{ 'Please fill this field' | translate }}
                            </mat-error>
                            <mat-error *ngIf="form.get('last_name').errors?.['serverError']">
                                {{ form.get('last_name').errors?.['serverError'] }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field>
                            <mat-label>{{ "E-mail" | translate }}</mat-label>
                            <input type="text" matInput formControlName="email" name="email" required [errorStateMatcher]="matcher" autocomplete="new-password">
                            <mat-error *ngIf="form.get('email').errors?.['email']">
                                {{ 'Please enter a valid e-mail address' | translate }}
                            </mat-error>
                            <mat-error *ngIf="form.hasError('emailNotConfirmed')">
                                {{ "The e-mail confirmation does not match" | translate }}
                            </mat-error>
                            <mat-error *ngIf="form.get('email').errors?.['serverError']">
                                {{ form.get('email').errors?.['serverError'] }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field>
                            <mat-label>{{ "E-mail confirmation" | translate }}</mat-label>
                            <input type="text" matInput formControlName="email_confirmation" name="email_confirmation" required autocomplete="new-password">
                            <mat-error *ngIf="form.get('email_confirmation').errors?.['email']">
                                {{ 'Please enter a valid e-mail address' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field>
                            <mat-label>{{ "Password" | translate }}</mat-label>
                            <input type="password" matInput formControlName="password" name="password" required [errorStateMatcher]="matcher" autocomplete="new-password">
                            <mat-error *ngIf="form.get('password').errors?.['required']">
                                {{ 'Please fill this field' | translate }}
                            </mat-error>
                            <mat-error *ngIf="form.hasError('passwordNotConfirmed')">
                                {{ "The password confirmation does not match" | translate }}
                            </mat-error>
                            <mat-error *ngIf="form.get('password').errors?.['serverError']">
                                {{ form.get('password').errors?.['serverError'] }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <p>
                        <mat-form-field>
                            <mat-label>{{ "Password confirmation" | translate }}</mat-label>
                            <input type="password" matInput formControlName="password_confirmation" name="password_confirmation" required autocomplete="new-password">
                            <mat-error *ngIf="form.get('password_confirmation').errors?.['required']">
                                {{ 'Please fill this field' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </p>
                    <input type="hidden" formControlName="phone">
                    <p class="accept-terms" *ngIf="!ofLegalAge">
                        <mat-checkbox matInput formControlName="ofLegalAge" name="ofLegalAge" required autocomplete="new-password">
                            {{ "I'm of age" | translate }}
                        </mat-checkbox>
                        <mat-error *ngIf="form.get('ofLegalAge').errors?.['required']">
                            {{ 'You must indicate that you are of age' | translate }}
                        </mat-error>
                    </p>
                    <p class="accept-terms">
                        Al crear una cuenta, aceptas los
                        <a href="#" (click)="$event.preventDefault(); showTerms();">Términos y condiciones</a>
                        y el <a href="#" (click)="$event.preventDefault(); showPrivacy();">Aviso de Privacidad</a>
                        de Moneo.
                    </p>
                    <div class="row">
                        <div class="col-12 text-center mt-2">
                            <button type="submit" mat-raised-button color="primary">{{ 'Create my account' | translate }}</button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
        <p class="accept-terms">
            Al crear una cuenta, aceptas los
            <a href="#" (click)="$event.preventDefault(); showTerms();">Términos y condiciones</a>
            y el <a href="#" (click)="$event.preventDefault(); showPrivacy();">Aviso de Privacidad</a>
            de Moneo.
        </p>
        <p class="my-4 text-center font-weight-bold">
            {{ "Do you have an account?" | translate }} <br>
            <a routerLink="/auth/login" [replaceUrl]="true" class="font-weight-bold h5">{{ 'Start your session' | translate }}</a>
        </p>
    </div>
</div>
