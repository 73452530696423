<mat-card class="faq">
    <mat-card-header>
        <div mat-card-avatar>
            <a backButton class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title>{{ "FAQ" | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mt-2">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="row">
                            <div class="col-num col-auto pr-0">1.</div>
                            <div class="col-title col-auto pl-0">¿Cómo gano cashback?</div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>Ganar cashback con Moneo es tan fácil cómo tomar una foto.</p>
                    <ol class="list">
                        <li>
                            Simplemente sube tu ticket para ganar un poco de cashback.<br/>
                            Es así de sencillo. ¡Nada más sube la foto y gana!
                        </li>
                        <li>
                            Y gana aún más con nuestras Ofertas Especiales.<br/>
                            Si tu ticket incluye productos seleccionados, te damos aún más cashback.
                        </li>
                    </ol>
                    <p>Recuerda que es necesario que hagas tus compras en tiendas participantes y que sólo puedes subir un ticket por tienda con la misma fecha de compra.</p>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="row">
                            <div class="col-num col-auto pr-0">2.</div>
                            <div class="col-title col-auto pl-0">Tiendas participantes</div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>Puedes subir tickets de Walmart, Walmart Express / Superama, Soriana, 7-Eleven, Sumesa, City Market, La Europea, +Kota y varios lugares más. </p>
                    <p>Consulta la lista en la sección de Tiendas favoritas.</p>
                    <p>Por lo pronto, no aceptamos tickets de Bodega Aurrerá, Oxxo y Chedraui.</p>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="row">
                            <div class="col-num col-auto pr-0">3.</div>
                            <div class="col-title col-auto pl-0">Instrucciones para tomar una foto</div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <ul class="list">
                        <li>La foto necesita ser del ticket completo.</li>
                        <li>Toma la foto encima de una superficie sólida y oscura.</li>
                        <li>Toma una foto plana y rectangular... ¡No subas una foto chueca!</li>
                        <li>La foto necesita incluir el nombre del comercio, la fecha de compra y el monto de compra.</li>
                    </ul>
                    <p>Revisa el siguiente ejemplo que muestra claramente todos los datos:</p>
                    <div class="text-center">
                        <img src="/assets/img/cut-photo.jpg" alt="Cut photo"/>
                    </div>
                    <br>
                    <div class="text-center">
                        <button mat-raised-button color="primary" [routerLink]="['/tutorial']" class="btn-cashback">
                            Ver video
                        </button>
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="row">
                            <div class="col-num col-auto pr-0">4.</div>
                            <div class="col-title col-auto pl-0">
                                ¿Por qué la foto necesita ser plana, clara y completa?
                            </div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>Usamos tecnología avanzada para leer la foto y revisar los datos.</p>
                    <p>Si subes una foto borrosa, incompleta o difícil de leer, el sistema no podrá identificar la información clave y no la podremos procesar.</p>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="row">
                            <div class="col-num col-auto pr-0">5.</div>
                            <div class="col-title col-auto pl-0">¿Por qué se rechazó mi ticket?</div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <ul class="list">
                        <li>Se rechaza un ticket si no se puede leer la información clave.</li>
                        <li>Se rechaza un ticket si no incluye la información clave.</li>
                        <li>Se rechaza un ticket si no cumple con los términos del servicio.</li>
                    </ul>
                    <p><strong>La información clave</strong></p>
                    <p>Para procesar un ticket y entregarte tu cashback, es necesario que podamos leer:</p>
                    <ul class="list">
                        <li>El nombre del comercio.</li>
                        <li>La fecha de compra.</li>
                        <li>El monto de compra.</li>
                    </ul>
                    <ol class="list">
                        <li>Si la foto no incluye estos datos básicos, no la aceptamos.</li>
                        <li>Si la foto está borrosa, no la aceptamos.</li>
                    </ol>
                    <p><strong>Los términos del servicio</strong></p>
                    <p>No nos gustan mucho, pero necesitamos poner unas reglas para que podamos operar nuestro negocio de forma eficiente.</p>
                    <p>Tu ticket necesita cumplir con estas reglas:</p>
                    <ol class="list">
                        <li>El ticket debe ser de un comercio participante.</li>
                        <li>
                            Sólo podemos aprobar un ticket por comercio con la misma fecha de compra.<br />
                            Por ejemplo, si ya has recibido cashback con un ticket de Walmart, no subas otro con la misma fecha de compra.
                        </li>
                        <li>Tienes 7 días a partir de la fecha de compra para subir tu ticket.</li>
                        <li>
                            Sólo puedes subir tus propios tickets.<br />
                            Compartir tickets o subir tickets de otra gente está estrictamente prohibido.<br />
                            Tu cuenta puede ser suspendida si lo haces.
                        </li>
                    </ol>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="row">
                            <div class="col-num col-auto pr-0">6.</div>
                            <div class="col-title col-auto pl-0">¿Cómo recibo mis ganancias?</div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>Una vez que hayas acumulado $25 pesos podrás solicitar una transferencia bancaria.</p>
                    <ol class="list">
                        <li>Selecciona “Transferir mi dinero” en el menú.</li>
                        <li>Llena el formulario con tus datos bancarios.</li>
                        <li>Nosotros te enviaremos tus ganancias por transferencia bancaria.</li>
                    </ol>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="row">
                            <div class="col-num col-auto pr-0">7.</div>
                            <div class="col-title col-auto pl-0">¡Disfruta tu dinero!</div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>
                        “Yo compro tiempo aire con mis ganancias de Moneo.”<br />
                        –Edgar, Maestro.
                    </p>
                    <p>
                        “El mes pasado pagué mi cuenta de luz con mi dinero de Moneo.”<br />
                        –Isabel, Contadora.
                    </p>
                    <p>
                        “Con Moneo pago mi cuenta de Spotify.”<br />
                        –Mariana, Estudiante.
                    </p>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="row">
                            <div class="col-num col-auto pr-0">8.</div>
                            <div class="col-title col-auto pl-0">¿Cómo usamos tus datos?</div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>
                        Los datos de tus tickets, y tus respuestas a las encuestas opcionales, pueden ser utilizados
                        en reportes que entregamos a las marcas y los comercios. Estas empresas tienen interés en
                        entender las tendencias del mercado para que puedan optimizar sus operaciones comerciales.
                    </p>
                    <p>
                        Si deseas saber más, por favor, consulta nuestro
                        <a href="#" (click)="$event.preventDefault(); showPrivacy()">Aviso de Privacidad.</a>
                    </p>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="row">
                            <div class="col-num col-auto pr-0">9.</div>
                            <div class="col-title col-auto pl-0">¿Cuál es el modelo de negocios de Moneo?</div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <p>
                        Trabajamos con diversos negocios y marcas que nos pagan para ayudarles a promover sus
                        productos y servicios, y obtener información sobre las tendencias del mercado.
                    </p>
                    <p>Nosotros usamos nuestros ingresos para financiar las recompensas de cashback.</p>
                    <p>A cambio de darnos tus tickets, te damos dinero.</p>
                    <p>Pensamos que es un trato justo y claro. Entre más tickets nos das, más dinero recibes.</p>
                    <p><strong>En otras palabras, las marcas nos pagan a nosotros… ¡y nosotros te pagamos a ti!</strong></p>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-card-content>
</mat-card>