import {Component, OnInit} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {AuthService} from './services/auth.service';
import {ApiService} from './services/api.service';
import {UserService} from './services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {AppUpdateService} from './services/app-update.service';
import {environment} from './../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {Title} from '@angular/platform-browser';
import {AppInstallService} from './services/app-install.service';
import {GoogleAnalyticsService} from './services/google-analytics.service';
import { IDeviceResponse } from './models/auth';
import { firstValueFrom } from 'rxjs';
import { MatomoService } from './services/matomo.service';

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public showDesktopMessage:boolean = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public dialog: MatDialog,
        private titleService: Title,
        public authService: AuthService,
        public translate: TranslateService,
        private userService: UserService,
        private apiService: ApiService,
        public updateService: AppUpdateService,
        public installService: AppInstallService,
        public googleAnalyticsService: GoogleAnalyticsService,
        private matomoService: MatomoService,
    ) {
        // Solicitar token
        this.authService.requestToken().then(response => {
            this.checkDeviceType();
        })
        .catch(error => {});
        translate.addLangs(['en', 'es']);
        translate.setDefaultLang('en');

        //const browserLang = translate.getBrowserLang();
        // Establecer lenguaje del sistema
        let lang = environment.locale.match(/en|es/) ? environment.locale : 'en';
        let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // Actualizar timezone del usuario
        if (this.authService.isLoggedIn()) {
            this.userService.updateTimezone(tz).then(response => { }).catch(error => { });
            this.userService.getMyBalance().catch(error => {});
            this.userService.getMe().catch(error => {});
        }
        translate.use(lang);
    }

    ngOnInit() {
        // Escuchar navegación
        this.router.events.subscribe(async (event) => {
            if (event instanceof NavigationEnd) {
                // Actualizar título de la página
                await this.updatePageTitle();
                // Inicializar GA y matomo
                if (environment.env !== 'local') {
                    this.initGA(event.urlAfterRedirects);
                    this.initMatomo(event.urlAfterRedirects);
                }
            }
        });
    }

    private initGA(url: string) {
        let config = {
            'page_path': url,
        };
        let user = this.authService.getCurrentUser();
        if (user) {
            config['user_id'] = user.id;
        }
        gtag('config', environment.analytics.trackingCode, config);
        gtag('config', environment.analytics.trackingCodeGA4, config);
    }

    private initMatomo(url: string) {
        if (url.startsWith('/auth/login') || url.startsWith('/auth/register')) {
            // No rastrear las páginas del login y registro
            return;
        }
        let user = this.authService.getCurrentUser();
        let title = this.titleService.getTitle();
        this.matomoService.trackPageView(url, title, user);
    }

    private async updatePageTitle() {
        let title = this.titleService.getTitle();
        let child = this.activatedRoute.firstChild;
        while (child.firstChild) {
            child = child.firstChild;
        }
        if (child.snapshot.data['title']) {
            title = child.snapshot.data['title'];
        }
        title = await firstValueFrom(this.translate.get(title, {}))
        if (title !== 'Moneo') {
            title += ' | Moneo';
        }
        this.titleService.setTitle(title);
    }

    private checkDeviceType() {
        const url = 'catalogues/device-type';
        let request: Promise<IDeviceResponse>;
        const continueOnDesktop = sessionStorage.getItem('continueOnDesktop');
        if (continueOnDesktop && continueOnDesktop == '1') {
            return;
        }
        if (this.authService.isLoggedIn()) {
            request = this.apiService.request<IDeviceResponse>('get', url, {});
        } else {
            request = this.apiService.requestWithClientToken<IDeviceResponse>('guest/' + url);
        }
        request.then((response) => {
            this.showDesktopMessage = response.device && response.device === "desktop";
        });
    }

    public continueOnDesktop() {
        this.showDesktopMessage = false;
        sessionStorage.setItem('continueOnDesktop', '1');
    }
}
