<mat-card class="offer-card">
    <mat-card-header>
        <div mat-card-avatar>
            <a backButton class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title>Regresar</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="commerce-details" *ngIf="commerce">
            <div class="row m-0 no-gutters">
                <div class="col-7">
                    <div class="img-container float-left d-flex align-items-center h-100">
                        <img [src]="commerce.logo" alt="Logo" class="d-block mx-auto">
                    </div>
                    <div class="title-container float-left pl-2 d-flex align-items-center h-100">
                        <h1 class="mb-0" *ngIf="!isAmy">{{ commerce.name }}</h1>
                    </div>
                </div>
                <div class="col-5 text-right" *ngIf="!isAmy">
                    <button mat-raised-button color="primary" (click)="redeemCommerce(commerce)" class="btn-cashback">
                        {{ "Upload ticket" | translate }}
                    </button>
                </div>
            </div>
            <div class="mt-2">
                <p class="description m-0" [innerHTML]="commerce.description"></p>
            </div>
        </div>
        <div class="commerce-details" *ngIf="loading">
            <div class="row m-0">
                <div class="float-left mr-3">
                    <div class="d-block mx-auto">
                        <span class="skeleton-box rounded-circle" style="width: 60px; height: 60px"></span>
                    </div>
                </div>
                <div class="float-left">
                    <p class="mt-3">
                        <span class="skeleton-box" style="width: 200px; height: 25px"></span>
                    </p>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <p class="m-0">
                        <span class="skeleton-box" style="width: 95%;"></span>
                        <span class="skeleton-box" style="width: 80%;"></span>
                    </p>
                </div>
                <div class="col-6 text-right">
                    <p class="m-0">
                        <span class="skeleton-box" style="width: 95%; height: 30px;"></span>
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-3" *ngIf="commerceId">
            <app-offers [isAmy]="isAmy"
                [commerceId]="commerceId"
                [reload]="true"
                (commerceLoaded)="onCommerceLoaded($event)"
                (cardClick)="redeemOffer($event)"
                (shareClick)="shareWa($event)"
                #offers>
            </app-offers>
        </div>
        <div class="mt-3 mb-3">
            <p class="mb-1 subtitle">{{ "Terms and conditions" | translate }}</p>
            <div class="terms" *ngIf="commerce">
                <div *ngIf="commerce.termsConditions">{{ commerce.termsConditions }}</div>
                <div *ngIf="!commerce.termsConditions" class="p-1">
                    <a href="#" (click)="$event.preventDefault(); showTerms();">Ver Términos y Condiciones</a>
                </div>
            </div>
            <div class="terms" *ngIf="loading">
                <p class="m-0">
                    <span class="skeleton-box" style="width: 98%;"></span>
                    <span class="skeleton-box" style="width: 93%;"></span>
                    <span class="skeleton-box" style="width: 97%;"></span>
                    <span class="skeleton-box" style="width: 66%;"></span>
                </p>
            </div>
        </div>
        <div class="mt-3 mb-3">
            <app-favorite-commerces [numItems]="4"></app-favorite-commerces>
        </div>
    </mat-card-content>
</mat-card>
