import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import {Integrations} from "@sentry/tracing";

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

Sentry.init({
    dsn: environment.sentry.dsn,
    integrations: [
        new Integrations.BrowserTracing({
            tracingOrigins: environment.sentry.tracingOrigins,
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],
    tracesSampleRate: environment.sentry.tracesSampleRate,
});

if (['prod', 'staging'].indexOf(environment.env) !== -1) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
