<mat-card class="redeem-content disable-select">
    <mat-card-header>
        <div mat-card-avatar>
            <a href="#" (click)="$event.preventDefault(); goBack();" class="nav-back">
                <i class="fas fa-arrow-left"></i>
            </a>
        </div>
        <mat-card-title>{{ "Upload your ticket" | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="mt-3">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field class="mb-3" style="width: 100%;" appearance="fill" *ngIf="commerces">
                <mat-label>{{ "Select commerce" | translate }}</mat-label>
                <mat-select formControlName="commerce_id" required>
                    <ng-container *ngFor="let commerce of commerces">
                        <mat-option [value]="commerce.uuid" *ngIf="commerce.name != 'Amy'">
                            {{ commerce.name }}
                        </mat-option>
                    </ng-container>
                </mat-select>
                <mat-error *ngIf="form.get('commerce_id').errors?.['required']">
                    {{ 'Please fill this field' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('commerce_id').errors?.['serverError']">
                    {{ form.get('commerce_id').errors?.['serverError'] }}
                </mat-error>
            </mat-form-field>
            <p class="mb-3" *ngIf="loadingCommerces">
                <span class="skeleton-box commerces-loader">
                    Selecciona comercio
                </span>
            </p>
            <!-- <mat-form-field class="mb-3" style="width: 100%;" appearance="fill">
                <mat-label>{{ "Purchase payment type" | translate }}</mat-label>
                <mat-select formControlName="purchase_payment_type" required>
                    <mat-option *ngFor="let type of paymentTypes" [value]="type.slug">
                        {{ type.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('purchase_payment_type').errors?.required">
                    {{ 'Please fill this field' | translate }}
                </mat-error>
                <mat-error *ngIf="form.get('purchase_payment_type').errors?.serverError">
                    {{ form.get('purchase_payment_type').errors?.serverError }}
                </mat-error>
            </mat-form-field> -->
            <div [class]="{'mb-4': receipt}">
                <div class="text-center mb-4" *ngIf="receipt && !cropperActive">
                    <button type="submit" mat-raised-button color="primary" class="btn-redeem">
                        {{ 'Upload your ticket' | translate }}
                    </button>
                </div>
                <div #copperContainer>
                    <image-cropper (imageCropped)="onImageCropped($event)" drag-mode="none"
                            [zoomable]="false" [hide-input]="true"
                            preview-class="vh-50"
                            [height]="this.camHeight + 'px'" #cropper>
                    </image-cropper>
                </div>
            </div>
            <div *ngIf="!cropperActive">
                <p class="mb-1 subtitle">Para un cashback exitoso:</p>
                <div class="notes">
                    <ol class="pl-0 mb-0" style="list-style: none;">
                        <li class="d-flex mb-3"><b class="mr-2">1</b>Sube una foto del ticket completo que incluya la fecha de compra.</li>
                        <li class="d-flex mb-3"><b class="mr-2">2</b>Sube tu ticket dentro de 7 días.</li>
                        <li class="d-flex mb-3"><b class="mr-2">3</b>Sólo sube tus propios tickets.</li>
                        <li class="d-flex"><b class="mr-2">4</b>Sólo puedes subir un ticket por comercio con la misma fecha de compra.</li>
                    </ol>
                </div>
            </div>
            <div class="receipt-container mb-4 mt-4">
                <div class="pt-1" *ngIf="!cropperActive">
                    <div class="text-center file-container">
                        <label for="file" matRipple>
                            <i class="fas fa-camera"></i>
                        </label>
                        <input id="file" type="file" accept="image/*" (change)="readFile($event)">
                    </div>
                    <p class="text-center subtitle mt-3">Tomar o seleccionar foto</p>
                </div>
                <mat-error class="mt-2 mb-3" *ngIf="receiptError" style="font-size: 75%; padding-left: 11px;">
                    {{ receiptError ? receiptError : '.' }}
                </mat-error>
            </div>
        </form>
    </mat-card-content>
</mat-card>
