import { IFile } from './file';
import { Question, IQuestion } from './question';

export interface IQuestionnaire {
    id: number;
    title: string;
    time_text: string;
    description: string;
    is_active: number;
    banner_heading: string;
    banner_content: string;
    banner_button_text: string;
    image?: IFile;
    questions?: IQuestion[];
    image_path?: string;
    add_age_question?: boolean,
    add_gender_question?: boolean,
    add_income_question?: boolean,
}

export class Questionnaire {
    constructor(
        public id: number,
        public title: string,
        public timeText: string,
        public description: string,
        public image: string,
        public isActive: boolean,
        public bannerHeading: string,
        public bannerContent: string,
        public bannerButtonText: string,
        public questions?: Question[],
    ) { }

    public static fromJson(data: IQuestionnaire): Questionnaire {
        return new Questionnaire(
            data['id'],
            data['title'],
            data['time_text'],
            data['description'],
            data['image'] ? data['image'].path : null,
            data['is_active'] == 1,
            data['banner_heading'],
            data['banner_content'],
            data['banner_button_text'],
            data['questions'] ? data['questions'].map(r => Question.fromJson(r)) : null,
        );
    }
}