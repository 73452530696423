<div *ngIf="showCookieConsentMessage" class="cookie-consent fixed-bottom py-3">
    <div class="container">
        <div class="d-flex align-items-center justify-content-center flex-wrap">
            <div class="mr-2">
                <p class="mb-0 cookie-consent__message">
                    Su experiencia en este sitio será mejorada con el uso de cookies.
                    <a href="/#" (click)="$event.preventDefault(); showPrivacy();" class="cookie-consent__privacy font-weight-bold">Ver aviso de privacidad</a>
                </p>
            </div>
            <div class="mt-2 mt-md-0">
                <button (click)="consentWithCookies()" mat-raised-button color="primary" class="cookie-consent__agree">
                    Aceptar
                </button>
            </div>
        </div>
    </div>
</div>
