<div class="login-box">
    <div class="auth-content">
        <div class="login-logo mt-5">
            <img src="assets/img/moneo-logo-fill-v1.svg" alt="Logo"/>
        </div>
        <mat-card class="login-content shadow-none px-0">
            <mat-card-content>
                <div class="alert alert-danger" *ngIf="formError">
                    {{ formError }}
                </div>
                <div class="alert alert-success" *ngIf="formSuccess">
                    <strong>{{ formSuccess }}</strong>
                </div>
                <p class="login-box-msg">
                    {{ 'For security reasons, system passwords are encrypted and can not be displayed.' | translate }}
                    <br><br>
                    {{ 'Therefore, if you forgot your password, you must enter your registered email and we will send you a link to reset it.' | translate }}
                </p>
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <p>
                        <mat-form-field>
                            <mat-label>{{ "E-mail" | translate }}</mat-label>
                            <input type="text" matInput formControlName="email" required>
                            <mat-error *ngIf="form.get('email').errors?.['required']">
                                {{ 'Please fill this field' | translate }}
                            </mat-error>
                            <mat-error *ngIf="form.get('email').errors?.['email']">
                                {{ 'Please enter a valid e-mail address' | translate }}
                            </mat-error>
                            <mat-error *ngIf="form.get('email').errors?.['serverError']">{{ form.get('email').errors?.['serverError'] }}</mat-error>
                        </mat-form-field>
                    </p>
                    <div class="row">
                        <div class="col-12">
                            <button type="submit" mat-raised-button color="primary">{{ 'Request password change' | translate }}</button>
                        </div>
                    </div>
                </form>
                <p class="mt-3 mb-1">
                    <a routerLink="/auth/login" [replaceUrl]="true">{{ 'I already have an account' | translate }}</a>
                </p>
            </mat-card-content>
        </mat-card>
    </div>
</div>
