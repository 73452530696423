<div>
    <p *ngIf="!isGuestUser" class="mb-2 subtitle">Tiendas favoritas</p>
    <p *ngIf="isGuestUser" class="mb-2 subtitle font-weight-bold">Ver más ofertas</p>
    <div class="favorite-commerces">
        <div class="row">
            <div [class]="columnsClass" *ngFor="let c of commerces" matRipple>
                <div class="commerce-link" (click)="showCommerceDetails(c)">
                    <img [src]="c.logo" alt="Logo" class="w-100 commerce-logo">
                    <p>{{ c.name }}</p>
                </div>
            </div>
            <ng-container *ngIf="loading">
                <div [class]="columnsClass" *ngFor="let item of loadingItems">
                    <div>
                        <div class="d-block mx-auto w-100">
                            <span class="skeleton-box rounded-circle" style="width: 57px; height: 57px;"></span>
                        </div>
                        <p class="mt-1 mb-1">
                            <span class="skeleton-box" style="width: 100%;"></span>
                        </p>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="showMoreOffersButton" [class]="columnsClass" matRipple>
                <div class="commerce-link more-commerces w-100" (click)="showCommerces()">
                    <div class="more-commerces__icon-container position-relative d-flex align-items-center justify-content-center">
                        <img src="assets/img/transparent.png" alt="Logo" class="d-block w-100 commerce-logo" #imgMore>
                        <i class="fas fa-arrow-right d-block position-absolute more-commerces__icon" [style.font-size]="iconMoreFs"></i>
                    </div>
                    <p>Ver más</p>
                </div>
            </div>
        </div>
    </div>
</div>