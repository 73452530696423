import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Cashback } from '../../models/cashback';
import { CashbackService } from '../../services/cashback.service';
@Component({
    selector: 'app-latest-winners',
    templateUrl: './latest-winners.component.html',
    styleUrls: ['./latest-winners.component.scss']
})
export class LatestWinnersComponent implements OnInit, AfterViewChecked {
    @ViewChild('listWinners', { static: false }) listWinners: ElementRef;
    public cashbacks: Cashback[];
    public runAnimation:boolean = false;
    private isAnimationRunning:boolean = false;

    constructor(private cashbackService: CashbackService) { }

    ngOnInit(): void {
        this.getCashbacks();
    }

    ngAfterViewChecked(): void {
        if (
            !this.isAnimationRunning &&
            this.runAnimation &&
            this.cashbacks &&
            this.listWinners.nativeElement.childElementCount === this.cashbacks.length
        ) {
            this.animate();
        }
    }

    private getCashbacks(): void {
        const params = {
            num_records: 20,
        };
        this.cashbackService.getCashbacks(params)
            .then(cashbacks => {
                this.cashbacks = cashbacks;
                // Para que la animación se pueda ejecutar debe de haber más de tres elementos
                this.runAnimation = this.cashbacks.length > 3;
            })
            .catch(error => { });
    }

    private animate() {
        if (!this.runAnimation) {
            return;
        }
        this.isAnimationRunning = true;
        // Mover el primer elemento de la lista al final
        setInterval(() => {
            this.listWinners.nativeElement.appendChild(this.listWinners.nativeElement.firstElementChild);
        }, 2000);
    }

}
