import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { WindowService } from 'src/app/services/window.service';

@Component({
  selector: 'app-slider-tutorial',
  templateUrl: './slider-tutorial.component.html',
  styleUrls: ['./slider-tutorial.component.scss']
})
export class SliderTutorialComponent implements OnInit, AfterViewInit {
    @ViewChild('elSliderTutorial', { static: false }) elSliderTutorial: ElementRef;
    @ViewChild('carousel', { static: false }) carousel: NgbCarousel;
    public activeSlide: string = 'slide-0';
    public tutorialIdx: number = 0;
    private carouselItems: any;

    constructor(
        public dialogRef: MatDialogRef<SliderTutorialComponent>,
        public windowService: WindowService,
        @Inject(MAT_DIALOG_DATA) public data: { [k: string]: any },
        private elComponent: ElementRef,
        private router: Router,
    ) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.setRootDivWidth();
        this.carouselItems = this.elComponent.nativeElement.querySelectorAll('.carousel-item');
        this.setSliderImageHeight();
    }

    @HostListener('window:resize')
    onResize() {
        this.setRootDivWidth();
    }

    public closeModal() {
        this.dialogRef.close();
    }

    private setRootDivWidth() {
        let divWidth = this.elSliderTutorial.nativeElement.offsetWidth;
        this.elSliderTutorial.nativeElement.style.width = divWidth + 'px';
    }

    private setSliderImageHeight() {
        let elActiveSlideItem = null;
        let elImg = null;
        let sumHeights = 0;
        for (let i = 0; i < this.carouselItems.length; i++) {
            if (this.carouselItems[i].id === 'slide-' + this.activeSlide) {
                elActiveSlideItem = this.carouselItems[i];
                break;
            }
        }
        let elChildren = elActiveSlideItem.children;
        for (let i = 0; i < elChildren.length; i++) {
            if (elChildren[i].classList.contains('picsum-img-wrapper')) {
                elImg = elChildren[i];
            } else {
                let styles = window.getComputedStyle(elChildren[i]);
                let elHeight = elChildren[i].offsetHeight + parseInt(styles['margin-top']) + parseInt(styles['margin-bottom']);
                sumHeights += (elHeight > 0 ? elHeight : 0);
            }
        }
        elImg.style.height = `calc(100% - ${sumHeights}px)`;
    }

    public onSlide(payload: NgbSlideEvent) {
        this.activeSlide = payload.current;
    }

    public onSlid(payload: NgbSlideEvent) {
        this.setSliderImageHeight();
    }
 
    public next() {
        this.carousel.next();
    }

    public select(index: number) {
        this.tutorialIdx = index;
        this.carousel.select(`slide-${index}`);
    }

    public tutorialNext() {
        if (this.tutorialIdx == 3) {
            this.closeModal();
        } else {
            this.tutorialIdx++;
            this.carousel.next();
        }
    }

    public tutorialPrevious() {
        if (this.tutorialIdx > 0) {
            this.tutorialIdx--;
            this.carousel.prev();
        }
    }

    public onSwipe(evt) {
        const x = Math.abs(evt.deltaX) > 40 ? (evt.deltaX > 0 ? 'right' : 'left') : '';
        const y = Math.abs(evt.deltaY) > 40 ? (evt.deltaY > 0 ? 'down' : 'up') : '';
        if (x === 'left') {
            this.tutorialNext();
        }
        else {
            this.tutorialPrevious();
        }
    }

    public goToRegister() {
        this.closeModal()
        this.router.navigate(['auth/register']);
    }

    public goToOffers() {
        this.closeModal();
        this.router.navigate(['guest/offers'], {queryParams: { commerce: '2ae51c39-c3ec-4473-b966-1d28fbdbde19' }});        
    }
}
