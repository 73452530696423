import { MatDialog } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { User } from '../../models/user';
import { QuestionnaireComponent } from './../questionnaire/questionnaire.component';
import { Offer } from '../../models/offer';
import { Commerce } from '../../models/commerce';
import { environment } from './../../../environments/environment';
import { WindowService } from '../../services/window.service';
import { UserService } from './../../services/user.service';
import { AppInstallService } from '../../services/app-install.service';
import { Subscription } from 'rxjs';
import { BROWSER_STORAGE } from '../../storage';
import { Balance } from '../../models/balance';
import { Utils } from '../../utils/index';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy{
    public user: User;
    public hasMorePages: boolean = true;
    public showTipBanner: boolean = true;
    public showQuestionnaireBanner: boolean = true;
    public installSubscription: Subscription;
    public standaloneSubscription: Subscription;
    public installed: boolean = true;
    public promptEvent = null;
    public userSubscription: Subscription;
    public balance: Balance;
    public europeaUuid: string;

    constructor(
        @Inject(BROWSER_STORAGE) private storage: Storage,
        private router: Router,
        private dialog: MatDialog,
        private authService: AuthService,
        private userService: UserService,
        private windowService: WindowService,
        public googleAnalyticsService: GoogleAnalyticsService,
        private installService: AppInstallService,
    ) {
        this.user = this.authService.getCurrentUser();
    }

    ngOnInit() {
        this.europeaUuid = '2ae51c39-c3ec-4473-b966-1d28fbdbde19';
        if (window.location.href.startsWith('https://app.dev01.moneo.clh.no/')) {
            this.europeaUuid = '2ae51c39-c3ec-4473-b966-1d28fbdbde19';
        }
        else if (window.location.href.startsWith('https://app.moneo.cash/')) {
            this.europeaUuid = '2ae51c39-c3ec-4473-b966-1d28fbdbde19';
        }
        this.installSubscription = this.installService.installEvent$.subscribe({
            next: (event) => {
                this.promptEvent = event;
            }
        });
        this.standaloneSubscription = this.installService.isStandalone$.subscribe({
            next: (standalone) => {
                this.installed = standalone;
            }
        });
        this.userSubscription = this.userService.authUserUpdated$.subscribe(user => this.user = user);
        this.showQuestionnaireBanner = this.userService.showQuestionnaire;
        this.showTipBanner = this.userService.showTip;
        this.userService.myBalance$.subscribe((balance) => {
            this.balance = balance;
        });
    }

    ngOnDestroy() {
        if (this.installSubscription) {
            this.installSubscription.unsubscribe();
        }
        if (this.standaloneSubscription) {
            this.standaloneSubscription.unsubscribe();
        }
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    public addToHomeScreen() {
        if (this.promptEvent == null) {
            return;
        }
        this.promptEvent.prompt();
        this.promptEvent.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            this.installed = true;
          }
          this.promptEvent = null;
        });
        this.googleAnalyticsService.eventEmitter("click_banner", "Banner");
    }

    public showWallet () {
        let wallet = this.balance.available;
        Utils.alert(this.dialog, {
            data: {
                message: "Tus ganancias: $ " + wallet,
                type: 'info'
            }
        });
    }

    public showDetails(offer: Offer) {
        this.googleAnalyticsService.eventEmitter("click_offer_details", "Offers", offer.getNameForGA());
        this.router.navigate(['commerces/', offer.commerce.uuid, 'offers'], {
            queryParams: { 'offer': offer.uuid }
        });
    }

    public shareWa(offer: Offer) {
        this.googleAnalyticsService.eventEmitter("click_share_offer_whatsapp", "Offers", offer.getNameForGA());
        let url = window.encodeURI(`${environment.webAppUrl}/guest/offers?offer=${offer.uuid}`);
        let msg = window.encodeURIComponent("Checa esta oferta en Moneo, la mejor app de cashback en México.");
        window.location.href = `whatsapp://send?text=${msg} ${url}`;
    }

    public closeBanner(wichBanner: string) {
        if (wichBanner === 'tip') {
            this.showTipBanner = false;
            this.userService.showTip = false;
        }
        else if (wichBanner === 'questionnaire') {
            this.showQuestionnaireBanner = false;
            this.userService.showQuestionnaire = false;
        }
        this.googleAnalyticsService.eventEmitter("close_banner", "Banner");
    }

    public showCommerces() {
        this.googleAnalyticsService.eventEmitter('click_view_more_commerces', 'Commerces');
        this.router.navigate(['/commerces']);
    }

    public showCommerceDetails(commerce: Commerce) {
        this.googleAnalyticsService.eventEmitter("click_commerce_details", "Commerces", commerce.name);
        this.router.navigate(['commerces/', commerce.uuid, 'offers']);
    }

    public showQuestionnaire() {
        this.router.navigate(['/questionnaire']);
    }

    public showBannerSoriana8() {
        this.googleAnalyticsService.eventEmitter("click_banner", "Banner", "soriana_8");
        let sorianaUuid = '5f0b1a86-a848-4ff6-96b4-d1343e81a70e';
        if (window.location.href.startsWith('https://app.dev01.moneo.clh.no/')) {
            sorianaUuid = '4bae53ba-6ccb-451a-b3ed-88cd65ff2c59';
        }
        else if (window.location.href.startsWith('https://app.moneo.cash/')) {
            sorianaUuid = '5f0b1a86-a848-4ff6-96b4-d1343e81a70e';
        }
        this.router.navigate(['/commerces/', sorianaUuid,'offers']);
    }

    public showBannerEuropeaGloria() {
        this.googleAnalyticsService.eventEmitter("click_banner", "Banner", "la_gloria_20");
        this.router.navigate(['/commerces/', this.europeaUuid,'offers']);
    }
}
