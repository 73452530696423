import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { WindowService } from '../../services/window.service';
import { SubscribersDialogComponent } from '../subscribers-dialog/subscribers-dialog.component'
import { MatomoService } from '../../services/matomo.service';

@Component({
    selector: 'app-key-actions',
    templateUrl: './key-actions.component.html',
    styleUrls: ['./key-actions.component.scss']
})
export class KeyActionsComponent implements OnInit {

    constructor(
        private googleAnalyticsService: GoogleAnalyticsService,
        private router: Router,
        private dialog: MatDialog,
        public windowService: WindowService,
        private matomoService: MatomoService,
    ) { }

    ngOnInit(): void {
    }

    public redeem() {
        this.googleAnalyticsService.eventEmitter("click_redeem_on_key_actions", "Cashbacks");
        this.router.navigate(['redeem']);
    }

    public profile() {
        this.googleAnalyticsService.eventEmitter("click_profile_on_key_actions", "engagement");
        this.matomoService.trackEvent('Profile', 'show_from_key_actions');
        this.router.navigate(['profile']);
    }

    public showTutorial() {
        this.googleAnalyticsService.eventEmitter("click_show_tutorial_on_key_actions", "Tutorial");
        this.router.navigate(['tutorial']);
    }

    public showSubscribersDialog() {
        this.googleAnalyticsService.eventEmitter("click_show_subscribers_dialogs_on_key_actions", "Subscribers");
        this.dialog.open(SubscribersDialogComponent, {
            disableClose: true,
            maxWidth: '270px',
            autoFocus: false
        });
    }
}
