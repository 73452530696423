import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { User } from '../../models/user';
import { SubscribersService } from '../../services/subscribers.service';
import { Utils } from '../../utils';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';
import { MatomoService } from '../../services/matomo.service';

@Component({
    selector: 'app-subscribers-dialog',
    templateUrl: './subscribers-dialog.component.html',
    styleUrls: ['./subscribers-dialog.component.scss']
})
export class SubscribersDialogComponent implements OnInit {
    private user: User;
    constructor(
        private dialogRef: MatDialogRef<SubscribersDialogComponent>,
        private authService: AuthService,
        public subscribersService: SubscribersService,
        private dialogLoading: MatDialog,
        private _snackBar: MatSnackBar,
        private googleAnalyticsService: GoogleAnalyticsService,
        private matomoService: MatomoService,
    ) {
        this.user = this.authService.getCurrentUser();
    }

    ngOnInit(): void {
    }

    public registerEmail() {
        if (!this.user) {
            return;
        }
        let data = {
            email: this.user.email,
            source: 'referral-programing-waitinglist'
        }
        let ref = Utils.alert(this.dialogLoading, {
            data: {
                message: 'Agregándote' + '...',
                showAcceptButton: false,
                type: 'loading',
            },
            disableClose: true,
        });
        this.googleAnalyticsService.eventEmitter("click_add_to_list_on_subscribers_dialog", "Subscribers");
        this.subscribersService.saveEmail(data).then((response) => {
            this.matomoService.trackEvent('Subscribers', 'joined_from_subscribers_dialog');
            this._snackBar.open(response.message, 'Aceptar', {
                duration: 8000
            });
        })
        .catch((response: HttpErrorResponse) => {
            if (response.status === 422) {
                if (response.error.errors) {
                    this._snackBar.open(response.error.errors['email'][0], 'Aceptar', {
                        duration: 8000
                    });
                }
            }
        })
        .finally(() => {
            ref.close();
            this.close();
        });
    }

    public close() {
        this.googleAnalyticsService.eventEmitter("click_close_on_subscribers_dialog", "Subscribers");
        this.dialogRef.close();
    }
}
